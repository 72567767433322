import { FC, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import ReactSignatureCanvas from "react-signature-canvas";

import PrimaryButton from "components/PrimaryButton";
import StyledButtonSecondaryGreyMini from "components/StyledButtonSecondaryGreyMini";
import { StyledContainer } from "components/StyledContainer";

type SignatureDialogProps = {
  handleClose: () => void;
  onSave: (value: any) => void;
};

export const SignatureDialog: FC<SignatureDialogProps> = (props) => {
  const { handleClose, onSave } = props;
  const canvasReference = useRef<ReactSignatureCanvas | null>(null);
  const [value, setValue] = useState<string | null>(null);

  const getCanvasParentWidth = (): number => {
    const parent = canvasReference.current?.getCanvas().parentElement;

    if (parent) {
      const parentStyle = getComputedStyle(parent);
      const width =
        parseFloat(parentStyle.width.replace("px", "")) -
        parseFloat(parentStyle.paddingLeft.replace("px", "")) -
        parseFloat(parentStyle.paddingRight.replace("px", ""));

      return width;
    } else {
      return 500;
    }
  };

  const [canvasWidth, setCanvasWidth] = useState(getCanvasParentWidth());

  useEffect(() => {
    const resizeCanvas = () => {
      setCanvasWidth(getCanvasParentWidth());
    };

    resizeCanvas();

    window.addEventListener("resize", resizeCanvas);
    return () => window.removeEventListener("resize", resizeCanvas);
  }, []);

  return (
    <StyledContainer data-testid="SignatureDialog">
      <ReactSignatureCanvas
        penColor="black"
        canvasProps={{
          width: canvasWidth,
          height: 200,
        }}
        ref={canvasReference}
        onEnd={() => setValue(canvasReference.current?.getTrimmedCanvas().toDataURL() ?? null)}
      />
      <StyledButtonSecondaryGreyMini
        onClick={() => {
          canvasReference.current?.clear();
          setValue(null);
        }}
        data-testid="SignatureDialogClearButton"
      >
        <FormattedMessage id="general.clear" />
      </StyledButtonSecondaryGreyMini>
      <PrimaryButton
        fullWidth
        onClick={() => {
          handleClose();
          onSave(value);
        }}
        data-testid="SignatureDialogSaveButton"
      >
        <FormattedMessage id="general.save" />
      </PrimaryButton>
    </StyledContainer>
  );
};