import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, FormHelperText, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AsolviSwitch } from "components/AsolviSwitch";
import StyledTextField from "components/StyledTextField";
import { useAppDispatch, useAppSelector } from "store";
import { selectSelectedJobVisit, setVisitValue } from "store/slices/jobs.store";
import { Signoff } from "./Signoff";

interface JobSignoffProps {
  handleSignature: (value: string) => void;
}

export const JobSignoff = (props: JobSignoffProps) => {
  const { handleSignature } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { customerSignerName, skipSignature, signatureCustomer } =
    useAppSelector(selectSelectedJobVisit);

  const [expandedSignature, setExpandedSignature] = useState(true);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <StyledTextField
          id="signature-name"
          name="customerSignerName"
          label={intl.formatMessage({ id: "job.customer.name" })}
          value={customerSignerName}
          onChange={({ target: { value } }) => {
            dispatch(setVisitValue({ key: "customerSignerName", value }));
          }}
          error={!customerSignerName}
        />
        {!customerSignerName && (
          <FormHelperText error>
            {intl.formatMessage({ id: "visit.signoff.customerSignerNameRequired" })}
          </FormHelperText>
        )}
      </Grid>
      <Grid item container justifyContent="space-between" alignItems="center" wrap="nowrap">
        <Grid item xs={10}>
          <FormattedMessage id="visit.signoff.skipSignature" />
        </Grid>
        <Grid item>
          <AsolviSwitch
            checked={skipSignature}
            onChange={({ target: { checked } }) => {
              dispatch(setVisitValue({ key: "skipSignature", value: checked }));
            }}
            name="skipSignature"
            className="e2e-skip-signature-switch"
          />
        </Grid>
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        <Accordion disabled={skipSignature} expanded={expandedSignature && !skipSignature}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpandedSignature(!expandedSignature)}
          >
            <Typography>
              <FormattedMessage id="general.signature" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Signoff
              handleSignature={handleSignature}
              label={intl.formatMessage({ id: "visit.signoff.customerSignature" })}
              signatureData={signatureCustomer}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};
