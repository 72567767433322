import { DriveEta as DriveEtaIcon } from "@mui/icons-material";
import { FormHelperText, Grid } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { formatTimeDiff, isAbortError, mergeDateTime, toDate, toDateString } from "helpers";
import { cloneDeep } from "lodash";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Times } from "models/travelTimes";
import { useAppDispatch, useAppSelector } from "store";
import {
  addTravel,
  selectSelectedJob,
  selectSelectedJobVisit,
  setTravelEta,
  setTravelMileage,
  setTravelTimes,
  startVisitTravel,
  stopTravel,
  updateETA,
  updateVisitTravel,
} from "store/slices/jobs.store";

import { AsolviTimePicker } from "components/AsolviTimePicker";
import PrimaryButton from "components/PrimaryButton";
import { PromptDialog } from "components/PromptDialog";
import SecondaryGreyButton from "components/SecondaryGreyButton";
import StyledTextField from "components/StyledTextField";
import { addSnackbarMessage } from "store/slices/snackbar.store";
import TimeBlockComponent from "./TimeBlockComponent";
import { TotalTimeComponent } from "./TotalTimeComponent";

export type TravelType = {
  travelTimes: Times[];
  eta?: Date | null;
  mileage?: number;
};

interface TravelTabProps {
  handleClose?: () => void;
}

export const TravelTab: FC<TravelTabProps> = (props) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectSelectedJob);
  const { travelTimes, travelEta, travelMileage, errors } = useAppSelector(selectSelectedJobVisit);

  const [openDialog, setOpenDialog] = useState(false);
  const [index, setIndex] = useState(0);
  const [isLastOneEnded, setIsLastOneEnded] = useState(true);

  const showETAandMileage = travelTimes[0] && travelTimes[0].startTime !== null;

  useEffect(() => {
    if (travelTimes.length === 0) {
      setIsLastOneEnded(true);
    } else {
      setIsLastOneEnded(
        travelTimes[travelTimes.length - 1].stopDate && travelTimes[travelTimes.length - 1].stopTime
          ? true
          : false
      );
    }
  }, [travelTimes]);

  const dispatchUpdate = useCallback(
    async (times: Times[]) => {
      await dispatch(setTravelTimes({ times: cloneDeep(times) }));
      await dispatch(updateVisitTravel({ jobId: job.id }))
        .then(unwrapResult)
        .catch((e) => {
          if (isAbortError(e)) return;
        });
    },
    [dispatch, job.id]
  );

  const timeBlockOnChange = (
    id: number,
    fieldname: "startDate" | "startTime" | "stopDate" | "stopTime",
    value: Date
  ) => {
    let newTimes = [...cloneDeep(travelTimes)];
    newTimes[id][fieldname] = toDateString(value);
    dispatchUpdate(newTimes);
  };

  const updateTravelCb = useCallback(async () => {
    await dispatch(updateVisitTravel({ jobId: job.id }))
      .then(unwrapResult)
      .then(({ queued }) => {
        if (queued) {
          dispatch(addSnackbarMessage({ key: "UpdateTravel-stored" }));
        } else {
          dispatch(addSnackbarMessage({ key: "UpdateTravel-success" }));
        }
      })
      .catch((e) => {
        if (isAbortError(e)) return;
        dispatch(addSnackbarMessage({ key: "UpdateTravel-fail" }));
      });
  }, [dispatch, job.id]);

  const onStartTravel = useCallback(async () => {
    await dispatch(addTravel());
    if (travelTimes.length === 0) {
      await dispatch(startVisitTravel({ jobId: job.id }))
        .then(unwrapResult)
        .then(({ queued }) => {
          if (queued) {
            dispatch(addSnackbarMessage({ key: "UpdateTravel-stored" }));
          } else {
            dispatch(addSnackbarMessage({ key: "StartTravel-success" }));
          }
        })
        .catch((e) => {
          if (isAbortError(e)) return;
          dispatch(addSnackbarMessage({ key: "StartTravel-fail" }));
        });
    } else {
      updateTravelCb();
    }
  }, [dispatch, job.id, travelTimes.length, updateTravelCb]);

  const onStopTravel = useCallback(async () => {
    await dispatch(stopTravel());
    updateTravelCb();
    props.handleClose?.();
  }, [dispatch, props, updateTravelCb]);

  const deleteTravelTime = (index: number) => {
    if (travelTimes.length === 1) {
      dispatch(setTravelEta({ eta: "" }));
      dispatch(setTravelMileage({ mileage: 0 }));
    }
    const newArray = travelTimes.filter((time) => time !== travelTimes[index]);
    dispatchUpdate(newArray);
  };

  return (
    <>
      <Grid container direction="column" spacing={1} width="100%" pl={1} pr={1}>
        {travelTimes.map((times, index) => {
          return (
            <div key={`${times.startTime}`}>
              {times.startTime !== null && (
                <Grid item>
                  <TimeBlockComponent
                    setOpenDeleteDialog={setOpenDialog}
                    setIndex={setIndex}
                    timeBlockOnChange={timeBlockOnChange}
                    id={index}
                    isDeleteDisabled={travelTimes.length === 1}
                    showLastOne={!(!isLastOneEnded && travelTimes.length - 1 === index)}
                    timesLabel="travelTimes"
                    timesText={intl.formatMessage({
                      id: "times.travelTime",
                    })}
                    totalTime={
                      times.stopTime && times.stopDate
                        ? formatTimeDiff(
                            intl,
                            Math.floor(
                              (mergeDateTime(times.stopDate, times.stopTime).getTime() -
                                mergeDateTime(times.startDate, times.startTime).getTime()) /
                                1000 /
                                60
                            )
                          )
                        : ""
                    }
                  />
                </Grid>
              )}
            </div>
          );
        })}

        <Grid
          item
          container
          justifyContent="space-between"
          direction="column"
          width="100%"
          spacing={1}
          wrap="nowrap"
          sx={{
            mt: 1,
            ml: 0,
            pl: "0 !important",
          }}
        >
          {showETAandMileage && (
            <Grid item container spacing={1} sx={{ pl: "0 !important" }} wrap="nowrap">
              <Grid item xs={12}>
                <AsolviTimePicker
                  name={`eta`}
                  label={intl.formatMessage({ id: "visit.eta" })}
                  value={toDate(travelEta)}
                  onChange={(value: Date | null) => {
                    if (value != null && toDate(travelEta)?.getTime() !== value?.getTime()) {
                      dispatch(setTravelEta({ eta: toDateString(value) }));
                      dispatch(updateETA({ jobId: job.id }))
                        .then(unwrapResult)
                        .catch((e) => {
                          if (isAbortError(e)) return;
                        });
                    }
                  }}
                />
              </Grid>
            </Grid>
          )}
          {showETAandMileage && (
            <Grid item container spacing={1} sx={{ pl: "0 !important" }} wrap="nowrap">
              <Grid item xs={12}>
                <StyledTextField
                  error={!!errors?.travelMileage}
                  inputProps={{ inputMode: "numeric", pattern: "d*" }}
                  id="total-miles"
                  name="mileage"
                  label={intl.formatMessage({ id: "visit.totalMiles" })}
                  value={travelMileage}
                  onChange={(e: any) => {
                    const { value } = e.target;

                    if (/^\d+$/.test(value) || value === "") {
                      dispatch(setTravelMileage({ mileage: Number(value) }));

                      dispatchUpdate(travelTimes);
                    }
                  }}
                />
                <FormHelperText error>{errors?.travelMileage}</FormHelperText>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} ml={1}>
          <TotalTimeComponent times={travelTimes} />
        </Grid>

        {isLastOneEnded || !travelTimes[0]?.startTime ? (
          <Grid item xs={12}>
            <PrimaryButton fullWidth startIcon={<DriveEtaIcon />} onClick={onStartTravel}>
              {intl.formatMessage({ id: "visit.startTravel" })}
            </PrimaryButton>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <SecondaryGreyButton onClick={() => deleteTravelTime(travelTimes.length - 1)}>
                {intl.formatMessage({ id: "general.cancel" })}
              </SecondaryGreyButton>
            </Grid>
            <Grid item xs={12}>
              <PrimaryButton startIcon={<DriveEtaIcon />} onClick={onStopTravel}>
                {intl.formatMessage({ id: "visit.stopTravel" })}
              </PrimaryButton>
            </Grid>
          </>
        )}
      </Grid>
      <PromptDialog
        open={openDialog}
        setOpen={setOpenDialog}
        onOk={() => {
          deleteTravelTime(index);
        }}
        promptContent={<FormattedMessage id="times.deletePrompt" />}
        title={intl.formatMessage({ id: "visit.deleteTimesPrompt" })}
        okText={intl.formatMessage({ id: "general.continue" })}
      />
    </>
  );
};
