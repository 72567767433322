import { ManufacturerType } from "operations/schema/schema";

export const JobFilterStorageKey = "jobFilter";

export type AddPartFilter = {
  selectedManufacturers: ManufacturerType[];
};

export const defaultPartFilter: AddPartFilter = {
  selectedManufacturers: [],
};

type FilterKeys = (keyof typeof defaultPartFilter)[];
export const PartFilterKeys: FilterKeys = Object.keys(defaultPartFilter) as FilterKeys;
