import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";

import { StyledChips } from "components/StyledChips";
import { intlFormatTime, toDate } from "helpers";
import { ServiceJob } from "operations/schema/schema";
import { JobStyles } from "styles/JobStyles";

const classes = JobStyles.createClasses();
const StyledBox = JobStyles.defineStyles(classes);

interface JobCalendarEventProps {
  job: ServiceJob;
  hideTimes?: boolean;
  showEngineerName?: boolean;
}

export const JobCalendarEvent: FC<JobCalendarEventProps> = (props: JobCalendarEventProps) => {
  const { job, hideTimes, showEngineerName } = props;
  const intl = useIntl();
  const plannedEndText = job.plannedDate?.stopTime;
  const plannedDays =
    job.plannedDate?.stopTime && job.plannedDate?.startTime
      ? Math.floor(
          (toDate(job.plannedDate?.stopTime!).getTime() -
            toDate(job.plannedDate?.startTime!).getTime()) /
            1000 /
            60 /
            60 /
            24
        )
      : 0;
  const dateText = job.plannedDate?.startTime || job.responseDate;

  return (
    <StyledBox className="component-Job">
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item minWidth="100%">
          <Grid container>
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Typography variant="body2" component="h4" sx={{color: job.textColour}}>
                  <span className="e2e-externalId">{job.externalId}</span>
                </Typography>
              </Grid>
              <Grid item>
                {job.customer?.name && (
                  <Typography variant="body2">
                    <span className="e2e-customerName">{job.customer?.name}</span>
                  </Typography>
                )}
              </Grid>
              <Grid item className={classes.jobTime}>
                <Grid item container direction="row" alignItems="center" spacing={1}>
                  {dateText && !hideTimes && (
                    <Grid item>
                      <Typography variant="body2" color="secondary">
                        {intlFormatTime(intl, dateText)}
                        {plannedDays < 1 && plannedEndText
                          ? ` - ${intlFormatTime(intl, plannedEndText)}`
                          : plannedDays === 1
                          ? ` (+1 ${intl.formatMessage({ id: "date.day" })})`
                          : plannedDays > 1 &&
                            ` (+${plannedDays} ${intl.formatMessage({ id: "date.days" })})`}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {showEngineerName && job.engineerName && (
                <Typography variant="body2">
                  <span className="e2e-engineerName">{job.engineerName}</span>
                </Typography>
              )}
            </Grid>
          </Grid>
          <StyledChips
            isOnStop={job.customer?.isOnStop}
            category={job.category}
            priority={job.priority}
            symptoms={job.symptoms}
          />
        </Grid>
      </Grid>
    </StyledBox>
  );
};
