import { Inspection } from "models/inspection";
import { InspectionFilters } from "models/InspectionsFilter";
import { DeviceTestStatusEnum } from "pages/jobs/jobDetails/visit/inspection/DeviceTestStatusEnum";
import { isEmpty } from "./isEmpty";

export const filterInspectionsList = (
  inspections: Inspection[],
  filter: InspectionFilters,
  searchString: string
) => {
  const searchTerms = searchString.toLocaleLowerCase().split(" ");

  return inspections.filter((inspection: Inspection) => {
    const result = inspection.testResult?.toLocaleLowerCase();

    if (
      (filter.hidePassed && result === DeviceTestStatusEnum.Pass) ||
      (filter.hideFailed && result === DeviceTestStatusEnum.Fail) ||
      (filter.hideTested && isEmpty(result))
    ) {
      return false;
    }

    if (searchTerms.length > 0) {
      const terms = [
        inspection.specification.partNumber,
        inspection.specification.description,
        inspection.specification.location,
        inspection.specification.partNumber,
      ];
      const inspectionSearch = terms.join(" ").toLocaleLowerCase();
      return searchTerms.every((term) => inspectionSearch.includes(term));
    }
    return true;
  });
};
