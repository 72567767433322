import { Typography } from "@mui/material";
import { ComponentType, FC, useCallback, useLayoutEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { setAuth } from "store/slices/user.store";

import ConnectionStatusProvider from "context/ConnectionStatus";
import ConsentProvider from "context/ConsentProvider";
import { getAuth0, getIsAuth0 } from "context/auth0";
import { logoutUser } from "context/login/logout";

import InstallPWA from "components/InstallPWA";
import { PromptDialog } from "components/PromptDialog";

import { MenuAppBar } from "pages/appBar/MenuAppBar";
import { BookInPartsDialog } from "pages/dialogs/BookInPartsDialog";
import { FeedbackDialog } from "pages/dialogs/FeedbackDialog";
import { LocationSettingsDialog } from "pages/dialogs/LocationSettingsDialog";
import { QueueDialog } from "pages/dialogs/QueueDialog";

interface PrivateRouteProps {
  component: ComponentType<any>;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ component: Component }) => {
  const dispatch = useAppDispatch();
  const { authVar, userVar } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const intl = useIntl();

  const [openLogoutPrompt, setOpenLogoutPrompt] = useState(false);

  const logout = useCallback(async () => {
    logoutUser();
    if (!getIsAuth0()) {
      navigate("/login", { replace: true });
    }
  }, [navigate]);

  const reissueLogin = async () => {
    if (getIsAuth0()) {
      const auth0 = getAuth0();
      await dispatch(setAuth({ isLoggedIn: false }));
      localStorage.removeItem("authState");
      auth0?.logout({ returnTo: `${window.location.origin}/login/auth0login` });
    } else {
      // * Unable to test this until new product that does not use auth0 is implemented
      // * Leave this commented out for later
      // if (!userSettings.email || !userSettings.lastEndpoint) {
      //   enqueueCustomSnackbar("ReissueLogin-failed");
      //   logout();
      // }
      // setOpenPasswordLoginDialog(true);
      // * For now, just throw user out if they're not using auth0
      logout();
    }
  };

  useLayoutEffect(() => {
    if (!authVar.isLoggedIn) {
      // Check if user was previously logged in
      if (userVar) {
        setOpenLogoutPrompt(true);
      } else {
        logout();
      }
    }
  }, [authVar, logout, userVar]);

  const GracefulLogoutPrompt = () => (
    <PromptDialog
      open={openLogoutPrompt}
      setOpen={setOpenLogoutPrompt}
      onOk={() => {
        reissueLogin();
      }}
      onCancel={() => {
        logout();
      }}
      okText={intl.formatMessage({ id: "general.yes" })}
      cancelText={intl.formatMessage({ id: "general.no" })}
      title={intl.formatMessage({ id: "login.sessionTimedOut" })}
      promptContent={
        <Typography>
          {intl.formatMessage({ id: "login.sessionTimedOut" })}
          <br />
          {intl.formatMessage({ id: "login.signInAgainPrompt" })}
        </Typography>
      }
    />
  );

  return authVar.isLoggedIn ? (
    <ConsentProvider>
      <ConnectionStatusProvider>
        <MenuAppBar />
        <InstallPWA />
        <Component />
        <FeedbackDialog />
        <BookInPartsDialog />
        <QueueDialog />
        <LocationSettingsDialog />
      </ConnectionStatusProvider>
    </ConsentProvider>
  ) : (
    <>
      <GracefulLogoutPrompt />
    </>
  );
};
