import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { AppBar, Dialog, IconButton, Toolbar, Typography } from "@mui/material";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useAppDispatch, useAppSelector } from "store";
import { selectEngineerSettings } from "store/slices/user.store";
import {
  selectSelectedJob,
  selectSelectedJobVisit,
  setSignatureCustomer,
  setSignatureEngineer,
} from "store/slices/jobs.store";

import { StyledContainer } from "components/StyledContainer";
import Transition from "components/Transition";
import { CustomerSignoffComponent } from "./CustomerSignoffComponent";
import { EngineerSignoffComponent } from "./EngineerSignoffComponent";
import { isEmpty } from "helpers";

interface SignoffDialogProps {
  open: boolean;
  handleClose: () => void;
}

export const SignoffDialog: FC<SignoffDialogProps> = (props: SignoffDialogProps) => {
  const { open, handleClose } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectSelectedJob);
  const engineerSettings = useAppSelector(selectEngineerSettings);
  const { jobCategory, skipSignature, customerSignerName, signoffErrors, submitLoading, followUp } =
    useAppSelector(selectSelectedJobVisit);

  const [openCustomerReview, setOpenCustomerReview] = useState(false);
  const [signatureAdded, setSignatureAdded] = useState(false);
  const [isEngineerSignatureAdded, setEngineerSignatureAdded] = useState(false);

  const requireEngineerSignature = engineerSettings?.requireEngineerSignature ?? false;

  const isValid = () => {
    return !Object.keys(signoffErrors).length;
  };

  const onClose = () => {
    handleClose();
    setSignatureAdded(false);
    setEngineerSignatureAdded(false);
    setOpenCustomerReview(false);
  };

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label={intl.formatMessage({ id: "general.back" })}
            size="large"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className="AppBar-label" paddingRight="36px">
            {openCustomerReview ? (
              <FormattedMessage id="general.customer" />
            ) : (
              <FormattedMessage id="general.engineer" />
            )}{" "}
            / {`${job?.externalId} `} <FormattedMessage id="visit.signoff.summary" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <StyledContainer>
        {!openCustomerReview ? (
          <EngineerSignoffComponent
            handleSignature={(value: string) => {
              const hasValue = !isEmpty(value);
              if(hasValue) {
                dispatch(
                  setSignatureEngineer({
                    signatureData: value,
                  })
                );
              } else {
                dispatch(
                  setSignatureEngineer({
                    signatureData: undefined,
                  })
                )
              }
              setEngineerSignatureAdded(hasValue);
            }}
            disableNextButton={
              submitLoading ||
              (followUp.followUpChecked && !jobCategory) ||
              (requireEngineerSignature && !isEngineerSignatureAdded)
            }
            loading={submitLoading}
            openCustomerReview={() => {
              setOpenCustomerReview(true);
            }}
          />
        ) : (
          <CustomerSignoffComponent
            handleClose={onClose}
            handleSignature={(value) => {
              const hasValue = !isEmpty(value);
              if(hasValue) {
                dispatch(
                  setSignatureCustomer({
                    signatureData: value,
                  })
                );
              } else {
                dispatch(
                  setSignatureCustomer({
                    signatureData: undefined,
                  })
                );
              }
              setSignatureAdded(hasValue);
            }}
            disableCompleteVisitButton={
              submitLoading ||
              (followUp.followUpChecked && !jobCategory) ||
              (!skipSignature && (!isValid() || !signatureAdded)) ||
              !customerSignerName
            }
            loading={submitLoading}
          />
        )}
      </StyledContainer>
    </Dialog>
  );
};
