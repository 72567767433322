import { AddressType, Maybe } from "operations/schema/schema";

export const addressToString = (address: Maybe<AddressType> | null | undefined) => {
  const { name, address1, address2, address3, postalCode, postalPlace, city } = address || {};

  const parts = [name, address1, address2, address3, city, postalCode, postalPlace];
  return parts.filter(Boolean).join(" ").trim();
};

export const addressToPostalLocation = (address: Maybe<AddressType> | null | undefined) => {
  const { postalCode, city } = address || {};

  const parts = [postalCode];
  parts.push(city);
  return parts.filter(Boolean).join(" ").trim();
};

export const addressToGoogleString = (address: Maybe<AddressType> | null | undefined) => {
  const { address1, address2, address3, postalCode, postalPlace, city } = address || {};

  const parts = [address1, address2, address3, city, postalCode, postalPlace];
  return parts.filter(Boolean).join(" ").trim();
};
