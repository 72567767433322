import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { cloneDeep } from "lodash";
import { Grid, Typography } from "@mui/material";

import { formatTimeDiff, mergeDateTime, toDateString } from "helpers";
import { Times } from "models/travelTimes";
import { useAppDispatch, useAppSelector } from "store";
import { selectSelectedJobVisit, setAutoEndTime, setVisitValue } from "store/slices/jobs.store";

import { AsolviSwitch } from "components/AsolviSwitch";
import { PromptDialog } from "components/PromptDialog";
import TimeBlockComponent from "./TimeBlockComponent";
import { TotalTimeComponent } from "./TotalTimeComponent";

export const WorkTab: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { autoEndTime, workTimes } = useAppSelector(selectSelectedJobVisit);

  const [openDialog, setOpenDialog] = useState(false);
  const [index, setIndex] = useState(0);

  const toggleAutoEndTime = () => {
    if (autoEndTime && !workTimes[workTimes.length - 1].stopTime) {
      stopWork();
    }
    dispatch(setAutoEndTime({ autoEndTime: !autoEndTime }));
  };

  const isLastOneEnded =
    !autoEndTime &&
    workTimes[workTimes.length - 1].stopDate !== null &&
    workTimes[workTimes.length - 1].stopTime !== null;

  const stopWork = () => {
    let times = cloneDeep(workTimes);

    let last = times.pop();
    if (last) {
      last.stopDate = toDateString(Date.now());
      last.stopTime = toDateString(Date.now());
      dispatch(setVisitValue({ key: "workTimes", value: [...times, last] }));
    }
  };

  const deleteWorkTime = (index: number) => {
    const newArray = workTimes.filter((time) => time !== workTimes[index]);
    dispatch(setVisitValue({ key: "workTimes", value: [...newArray] }));
  };

  const timeBlockOnChange = (
    id: number,
    fieldname: "startDate" | "startTime" | "stopDate" | "stopTime",
    value: Date
  ) => {
    let newTimes = cloneDeep(workTimes);
    newTimes[id][fieldname] = toDateString(value);
    dispatch(setVisitValue({ key: "workTimes", value: [...newTimes] }));
  };

  useEffect(() => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  }, [autoEndTime]);

  return (
    <>
      <Grid container direction="column" spacing={1} width="100%" pl={1} pr={1}>
        {workTimes.map((times: Times, index: number) => {
          return (
            <Grid key={index} item>
              <TimeBlockComponent
                setOpenDeleteDialog={setOpenDialog}
                setIndex={setIndex}
                timeBlockOnChange={timeBlockOnChange}
                id={index}
                isDeleteDisabled={workTimes.length === 1}
                showLastOne={!(!isLastOneEnded && workTimes.length - 1 === index)}
                timesLabel="workTimes"
                timesText={intl.formatMessage({ id: "times.workTime" })}
                totalTime={
                  times.stopTime && times.stopDate
                    ? formatTimeDiff(
                        intl,
                        Math.floor(
                          (mergeDateTime(times.stopDate, times.stopTime).getTime() -
                            mergeDateTime(times.startDate, times.startTime).getTime()) /
                            1000 /
                            60
                        )
                      )
                    : ""
                }
              />
            </Grid>
          );
        })}
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          spacing={1}
          wrap="nowrap"
          sx={{
            mt: 1,
            ml: 0,
            pl: "0 !important",
          }}
        >
          <Grid item ml={1}>
            <Typography variant="body1" onClick={toggleAutoEndTime}>
              <FormattedMessage id="times.setEndToCurrent" />
            </Typography>
          </Grid>
          <Grid item mr={1}>
            <AsolviSwitch checked={autoEndTime} onChange={toggleAutoEndTime} />
          </Grid>
        </Grid>
        <Grid item xs={12} ml={1}>
          <TotalTimeComponent times={workTimes} />
        </Grid>
      </Grid>
      <PromptDialog
        open={openDialog}
        setOpen={setOpenDialog}
        onOk={() => {
          deleteWorkTime(index);
        }}
        promptContent={<FormattedMessage id="times.deletePrompt" />}
        title={intl.formatMessage({ id: "visit.deleteTimesPrompt" })}
        okText={intl.formatMessage({ id: "general.continue" })}
      />
    </>
  );
};
