import { AppBar, Grid, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import theme from "theme";
import { FilterIcon } from "./FilterIcon";
import TabToolbarPlaceholder from "./TabToolbarPlaceholder";

export type FilterAppBarProps = {
  onClick: (open: boolean) => any;
  foundCount: number;
  filterCount?: number;
  type?: "jobs" | "parts";
  marginTop?: string;
};

export const FilterAppBar = (props: FilterAppBarProps) => {
  const { onClick, filterCount, foundCount, type, marginTop } = props;
  const intl = useIntl();

  const getCountFoundMessage = () => {
    const prefix = foundCount === 1 ? "filter.single" : "filter.multiple";
    switch (type) {
      case "jobs":
        return `${foundCount} ${intl.formatMessage({ id: `${prefix}.jobs` })}`;
      case "parts":
        return `${foundCount} ${intl.formatMessage({ id: `${prefix}.parts` })}`;
      default:
        return `${foundCount} ${intl.formatMessage({ id: "filter.found" })}`;
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          p: 1,
          pl: 3,
          pr: 3,
          backgroundColor: "grey.50",
          color: "inherit",
          marginTop: marginTop,
          [theme.breakpoints.down("sm")]: {
            marginTop: marginTop || '7px',
            pl: 2,
            pr: 2,
          },
          [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
            marginTop: marginTop || '6px',
          },
        }}
        onClick={() => onClick(true)}
        data-testid="filterAppBar"
      >
        <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
          <Grid item>
            <Typography variant="body1" display="inline" marginRight="16px">
              <FormattedMessage id="general.filters" />
            </Typography>
            <Typography variant="body2" color="secondary" display="inline">
              {getCountFoundMessage()}
            </Typography>
          </Grid>
          <Grid item>
            <FilterIcon value={filterCount ?? 0} />
          </Grid>
        </Grid>
      </AppBar>
      <TabToolbarPlaceholder
        sx={{
          mt: 2,
          [theme.breakpoints.down("sm")]: {
            mt: 1,
          },
          [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
            mt: 2,
          },
        }}
      />
    </>
  );
};
