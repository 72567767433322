import { FC } from "react";
import Typography from "@mui/material/Typography";

import { AddressType, Maybe } from "operations/schema/schema";
import { JobStylesClasses } from "styles/JobStyles";
import { addressToString } from "helpers/addressToString";

interface VisitDetailsProps {
  engineerName?: Maybe<string>;
  customerName?: Maybe<string>;
  customerAddress?: Maybe<AddressType>;
  equipmentName?: Maybe<string>;
  serialNumber?: Maybe<string>;
  location?: Maybe<string>;
  symptomDescription?: Maybe<string>;
  classes?: JobStylesClasses;
}

export const JobVisitDetails: FC<VisitDetailsProps> = (props) => {
  const {
    engineerName,
    customerName,
    customerAddress,
    equipmentName,
    serialNumber,
    location,
    symptomDescription,
    classes,
  } = props;

  const addressString = addressToString(customerAddress);

  return (
    <>
      {engineerName && (
        <Typography variant="body1">
          <span>{engineerName}</span>
        </Typography>
      )}
      {customerName && (
        <Typography variant="body1">
          <span className="e2e-customerName">{customerName}</span>
        </Typography>
      )}
      {customerAddress && (
        <Typography variant="body2" color="secondary" className="truncated">
          {addressString}
        </Typography>
      )}
      {equipmentName && <Typography variant="body1">{equipmentName}</Typography>}
      {serialNumber && (
        <Typography variant="body2" color="secondary">
          {serialNumber}
        </Typography>
      )}
      {location && (
        <Typography variant="body2" color="secondary" className={classes?.paragraph}>
          {location}
        </Typography>
      )}
      {symptomDescription && (
        <Typography
          variant="body2"
          color="secondary"
          className={`${classes?.ellipsis} ${classes?.paragraph} truncated`}
        >
          {symptomDescription}
        </Typography>
      )}
    </>
  );
};
