import { AppBar, Grid, Tab, Tabs } from "@mui/material";
import { isAbortError, isEmpty, toDateString } from "helpers";
import { cloneDeep } from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { addSnackbarMessage } from "store/slices/snackbar.store";
import {
  selectChecklistsIncomplete,
  selectSelectedJob,
  selectSelectedJobVisit,
  selectTravelTabInvalid,
  setVisitValue,
  startVisitWork,
} from "store/slices/jobs.store";
import { unwrapResult } from "@reduxjs/toolkit";
import { TabPanel } from "components/TabPanel";
import TabToolbarPlaceholder from "components/TabToolbarPlaceholder";
import { ChecklistTab } from "./ChecklistTab";
import { CompleteVisitButton } from "./CompleteVisitButton";
import { FileTab } from "./FileTab";
import { NotesTab } from "./NotesTab";
import { PartsTab } from "./PartsTab";
import { TasksTab } from "./TasksTab";
import { TimesTab } from "./TimesTab";
import { VisitTab } from "./VisitTab";
import { InspectionsTab } from "./InspectionTab";
import { SignoffDialog } from "./completion/SignoffDialog";

const errorKeys = {
  visit: ["causeId", "solutionDescription", "actionId1", "actionId2", "actionId3"],
  times: ["travelMileage", "startDate", "startTime", "stopDate", "stopTime"],
  checklists: [],
  parts: [],
  files: [],
  notes: [],
  tasks: [],
  inspections: [],
};

export const JobVisit: FC = () => {
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectSelectedJob);
  const { errors, autoEndTime, workTimes, travelTimes } =
    useAppSelector(selectSelectedJobVisit);
  const intl = useIntl();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState("visit");
  const isWorkStarted = useRef(false);
  const travelTabInvalid = useAppSelector(selectTravelTabInvalid);
  const checklistsIncomplete = useAppSelector(selectChecklistsIncomplete);
  // FAB
  const [fab, setFab] = useState<JSX.Element>();
  // Signoff
  const [openSignoff, setOpenSignoff] = useState(false);

  const tabHasError = (tabName: keyof typeof errorKeys) => {
    let searchKeys = errorKeys[tabName];
    let currentErrorKeys = Object.keys(errors);
    for (let key of currentErrorKeys) {
      if (searchKeys.some((sub) => key.includes(sub))) return true;
    }
    return false;
  };

  useEffect(() => {
    if (isWorkStarted.current || !job) return;

    if (isEmpty(workTimes) || !workTimes[0].startTime) {
      isWorkStarted.current = true;
      dispatch(startVisitWork({ jobId: job.id }))
        .then(unwrapResult)
        .then(({ queued }) => {
          if (queued) {
            dispatch(addSnackbarMessage({ key: "StartVisitWork-stored" }));
          } else {
            dispatch(addSnackbarMessage({ key: "StartVisitWork-success" }));
          }
        })
        .catch((e) => {
          if (isAbortError(e)) return;
          dispatch(addSnackbarMessage({ key: "StartVisitWork-fail" }));
        });
    } else {
      isWorkStarted.current = true;
    }
  }, [workTimes, isWorkStarted, dispatch, job]);

  const invalidTravelTime =
    !isEmpty(travelTimes) &&
    travelTimes[0].startTime !== null &&
    travelTimes[travelTimes.length - 1].stopTime === null;

  const onCompleteVisit = () => {
    if (invalidTravelTime) {
      dispatch(addSnackbarMessage({ key: "No-end-travel" }));
    } else {
        if (autoEndTime) {
          let workTimesCopy = cloneDeep(workTimes);
          let last = workTimesCopy.pop();
          if (last) {
            last.stopDate = toDateString(Date.now());
            last.stopTime = toDateString(Date.now());
            dispatch(setVisitValue({ key: "workTimes", value: [...workTimesCopy, last] }));
          }
        }
        setOpenSignoff(true);
        setTabValue("visit");
      }
      return null;
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          mt: 7,
        }}
      >
        <Tabs
          scrollButtons
          value={tabValue}
          onChange={(_, newValue: string) => {
            setTabValue(newValue);
          }}
          variant="scrollable"
          textColor="inherit"
        >
          <Tab
            label={
              <>
                {intl.formatMessage({ id: "general.visit" })}
                {tabHasError("visit") ? "*" : null}
              </>
            }
            className="e2e-visit-tab"
            value="visit"
            data-testid="visitTab"
          />
          <Tab
            label={
              <>
                {intl.formatMessage({ id: "job.times" })}
                {tabHasError("times") || travelTabInvalid ? "*" : null}
              </>
            }
            className="e2e-times-tab"
            value="times"
            data-testid="timesTab"
          />
          {!!job.checklists?.length && (
            <Tab
              label={
                <>
                  {intl.formatMessage({ id: "job.checklists" })}
                  {tabHasError("checklists") || checklistsIncomplete ? "*" : null}
                </>
              }
              className="e2e-checklists-tab"
              value="checklists"
              data-testid="checklistsTab"
            />
          )}
          {!!job.equipment?.specifications?.length && (
            <Tab
              label={
                <>
                  {intl.formatMessage({ id: "job.inspections" })}
                  {tabHasError("inspections") ? "*" : null}
                </>
              }
              className="e2e-inspections-tab"
              value="inspections"
              data-testid="specificationTab"
            />
          )}
          <Tab
            label={
              <>
                {intl.formatMessage({ id: "job.parts" })}
                {tabHasError("parts") ? "*" : null}
              </>
            }
            className="e2e-parts-tab"
            value="parts"
            data-testid="partsTab"
          />
          <Tab
            label={
              <>
                {intl.formatMessage({ id: "general.files" })}
                {tabHasError("files") ? "*" : null}
              </>
            }
            className="e2e-files-tab"
            value="files"
            data-testid="filesTab"
          />
          <Tab
            label={
              <>
                {intl.formatMessage({ id: "job.notes" })}
                {tabHasError("notes") ? "*" : null}
              </>
            }
            className="e2e-notes-tab"
            value="notes"
            data-testid="notesTab"
          />
          {!!job.tasks?.length && (
            <Tab
              label={
                <>
                  {intl.formatMessage({ id: "job.tasks" })}
                  {tabHasError("tasks") ? "*" : null}
                </>
              }
              className="e2e-work-tasks-tab"
              value="tasks"
              data-testid="tasksTab"
            />
          )}
        </Tabs>
      </AppBar>

      <TabToolbarPlaceholder />
      <TabPanel index="visit" value={tabValue}>
        <VisitTab handleFinished={() => navigate(-1)} setFab={setFab} />
      </TabPanel>
      <TabPanel index="times" value={tabValue}>
        <TimesTab setFab={setFab} />
      </TabPanel>
      <TabPanel index="parts" value={tabValue}>
        <PartsTab setFab={setFab} />
      </TabPanel>
      <TabPanel index="files" value={tabValue}>
        <FileTab setFab={setFab} />
      </TabPanel>
      <TabPanel index="notes" value={tabValue}>
        <NotesTab setFab={setFab} />
      </TabPanel>
      <TabPanel index="tasks" value={tabValue}>
        <TasksTab setFab={setFab} />
      </TabPanel>
      <TabPanel index="checklists" value={tabValue}>
        <ChecklistTab setFab={setFab} />
      </TabPanel>
      <TabPanel index="inspections" value={tabValue}>
        <InspectionsTab setFab={setFab} />
      </TabPanel>
      <Grid
        container
        direction="column"
        width="inherit"
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
        }}
      >
        <Grid item>{fab}</Grid>
        <Grid item>
          <CompleteVisitButton onCompleteVisit={onCompleteVisit} setTabValue={setTabValue} />
        </Grid>
      </Grid>
      <SignoffDialog open={openSignoff} handleClose={() => setOpenSignoff(false)} />
    </>
  );
};
