export type InspectionFilters = {
  hideTested: boolean;
  hidePassed: boolean;
  hideFailed: boolean;
};

export type InspectionsSorting = {
  sortingType: "lastServiceDate" | "sequenceumber";
  sortingOrder: "asc" | "desc";
};

export const defaultInspectionsFilter: InspectionFilters = {
  hideFailed: false,
  hidePassed: false,
  hideTested: false,
};
