import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { graphqlRequest } from "context/graphql/functions";
import { differenceInHours } from "date-fns";
import { jobsDictionaryToArray } from "helpers/jobsDictionaryToArray";
import { AvailablePartsSearch } from "models/AvailablePartsSearch";
import { PartQueryInputType, StockStore } from "operations/schema/schema";
import { AppAsyncThunkConfig } from "store";
import { CustomerPartsCache, State } from "./cache.store";

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

export const asyncQueries = {
  getCachePrefill: createAppAsyncThunk(
    "cache/getCachePrefill",
    async (props: { force?: boolean }, { getState, rejectWithValue, extra: { sdk } }) => {
      const { lastLoaded } = getState().cache;
      if (!props.force && lastLoaded && differenceInHours(new Date(lastLoaded), new Date()) < 72) {
        return rejectWithValue("No need to refresh yet");
      }
      const { data, errors } = await graphqlRequest(sdk.getCachePrefill);
      if (errors) return rejectWithValue(errors);
      if (!data?.jobActions) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getAvailableParts: createAppAsyncThunk(
    "cache/getAvailableParts",
    async (props: AvailablePartsSearch, { rejectWithValue, extra: { sdk } }) => {
      const { location } = props;
      const { data, errors } = await graphqlRequest(sdk.getAvailableParts, {
        variables: {
          //TODO; Necessary to handle multiple if all we do is query one at a time?
          locations: [location],
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.parts) return rejectWithValue("something went wrong");
      return { data, props };
    }
  ),
  getPartsCustomer: createAppAsyncThunk(
    "cache/getPartsCustomer",
    async (_, { getState, rejectWithValue, extra: { sdk } }) => {
      const {
        jobs: { jobs },
        user: { featureFlagVar },
      } = getState();
      const customerStockEnabled = !!featureFlagVar.find((f) => f.name === "CustomerStock")
        ?.isActive;

      if (!customerStockEnabled) {
        return rejectWithValue("Not enabled");
      }

      const jobsList = jobsDictionaryToArray(jobs);
      const customerIds = [...new Set(jobsList.map((j) => j.customer?.id ?? ""))];

      let parts: CustomerPartsCache = {};
      for (let id of customerIds) {
        if (id) {
          const { data, errors } = await graphqlRequest(sdk.getAvailableParts, {
            variables: {
              //TODO; Necessary to handle multiple if all we do is query one at a time?
              locations: [{ locationId: id, stockStore: StockStore.Customer }],
            },
          });
          if (errors) return rejectWithValue(errors);
          if (!data?.parts) return rejectWithValue("something went wrong");
          parts[id] = [...data.parts];
        }
      }
      return parts;
    }
  ),
  getPartsEngineer: createAppAsyncThunk(
    "cache/getPartsEngineer",
    async (props: { force?: boolean }, { getState, rejectWithValue, extra: { sdk } }) => {
      const {
        cache: { engineerPartsLastLoaded },
        user: { userVar },
      } = getState();
      if (
        !props.force &&
        engineerPartsLastLoaded &&
        differenceInHours(new Date(engineerPartsLastLoaded), new Date()) < 1
      ) {
        return rejectWithValue("No need to refresh yet");
      }
      const { data, errors } = await graphqlRequest(sdk.getAvailableParts, {
        variables: {
          locations: [
            {
              locationId: userVar?.stockId,
              stockStore: StockStore.Engineer,
            },
          ],
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.parts) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getPartsLocational: createAppAsyncThunk(
    "cache/getPartsLocational",
    async (_, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getAvailableParts, {
        variables: {
          //TODO; Necessary to handle multiple if all we do is query one at a time?
          locations: [{ locationId: "", stockStore: StockStore.Locational }],
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.parts) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getRequestableParts: createAppAsyncThunk(
    "cache/getRequestableParts",
    async (
      props: { location: PartQueryInputType; force?: boolean },
      { getState, rejectWithValue, extra: { sdk } }
    ) => {
      const { requestablePartsLastLoaded } = getState().cache;
      if (
        !props.force &&
        requestablePartsLastLoaded &&
        differenceInHours(new Date(requestablePartsLastLoaded), new Date()) < 1
      ) {
        return rejectWithValue("No need to refresh yet");
      }
      const { data, errors } = await graphqlRequest(sdk.getRequestableParts, {
        variables: {
          locations: [props.location],
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.requestableParts) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getSuggestedParts: createAppAsyncThunk(
    "cache/getSuggestedParts",
    async (variables: { equipmentId: string }, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getSuggestedParts, {
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.suggestedParts) return rejectWithValue("something went wrong");
      return { variables, data };
    }
  ),
  getSymptoms: createAppAsyncThunk(
    "cache/getSymptoms",
    async (_, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getSymptoms);
      if (errors) return rejectWithValue(errors);
      if (!data?.symptoms) return rejectWithValue("something went wrong");
      return data;
    }
  ),
};

export const queryBuilder = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(asyncQueries.getCachePrefill.pending, (state) => {
    state.loading.prefill = true;
    return state;
  });
  builder.addCase(asyncQueries.getCachePrefill.rejected, (state) => {
    state.loading.prefill = false;
    return state;
  });
  builder.addCase(asyncQueries.getCachePrefill.fulfilled, (state, { payload: data }) => {
    state.lastLoaded = new Date().toISOString();
    state.loading.prefill = false;
    state.actions = [...data.jobActions];
    state.causes = [...data.jobCauses];
    state.jobCategories = [...data.jobCategories];
    state.manufacturers = [...data.manufacturers];
    state.serviceLevels = [...data.serviceLevels];
    state.symptoms = [...data.symptoms];
    return state;
  });
  builder.addCase(asyncQueries.getAvailableParts.pending, (state) => {
    state.loading.availableParts = true;
    return state;
  });
  builder.addCase(asyncQueries.getAvailableParts.rejected, (state) => {
    state.loading.availableParts = false;
    return state;
  });
  builder.addCase(
    asyncQueries.getAvailableParts.fulfilled,
    (
      state,
      {
        payload: {
          data,
          props: { location },
        },
      }
    ) => {
      state.loading.availableParts = false;
      if (location.stockStore === StockStore.Engineer) {
        state.availableParts.engineerParts = [...data.parts];
        state.engineerPartsLastLoaded = new Date().toISOString();
      } else if (location.stockStore === StockStore.Customer) {
        const customerId = location.locationId;
        if (customerId) {
          state.availableParts.customerParts[customerId] = [...data.parts];
        }
      } else {
        state.availableParts.locationalParts = [...data.parts];
      }
      return state;
    }
  );
  builder.addCase(asyncQueries.getPartsCustomer.pending, (state) => {
    state.loading.customerParts = true;
    return state;
  });
  builder.addCase(asyncQueries.getPartsCustomer.rejected, (state) => {
    state.loading.customerParts = false;
    return state;
  });
  builder.addCase(asyncQueries.getPartsCustomer.fulfilled, (state, { payload: data }) => {
    state.loading.customerParts = false;
    state.availableParts.customerParts = { ...data };
    return state;
  });
  builder.addCase(asyncQueries.getPartsEngineer.pending, (state) => {
    state.loading.engineerParts = true;
    return state;
  });
  builder.addCase(asyncQueries.getPartsEngineer.rejected, (state) => {
    state.loading.engineerParts = false;
    return state;
  });
  builder.addCase(asyncQueries.getPartsEngineer.fulfilled, (state, { payload: data }) => {
    state.loading.engineerParts = false;
    state.availableParts.engineerParts = [...data.parts];
    state.engineerPartsLastLoaded = new Date().toISOString();
    return state;
  });
  builder.addCase(asyncQueries.getPartsLocational.pending, (state) => {
    state.loading.locationalParts = true;
    return state;
  });
  builder.addCase(asyncQueries.getPartsLocational.rejected, (state) => {
    state.loading.locationalParts = false;
    return state;
  });
  builder.addCase(asyncQueries.getPartsLocational.fulfilled, (state, { payload: data }) => {
    state.loading.locationalParts = false;
    state.availableParts.locationalParts = [...data.parts];
    return state;
  });
  builder.addCase(asyncQueries.getRequestableParts.pending, (state) => {
    state.loading.requestableParts = true;
    return state;
  });
  builder.addCase(asyncQueries.getRequestableParts.rejected, (state) => {
    state.loading.requestableParts = false;
    return state;
  });
  builder.addCase(asyncQueries.getRequestableParts.fulfilled, (state, { payload: data }) => {
    state.loading.requestableParts = false;
    state.requestableParts = [...data.requestableParts];
    state.requestablePartsLastLoaded = new Date().toISOString();
    return state;
  });
  builder.addCase(asyncQueries.getSuggestedParts.pending, (state) => {
    state.loading.suggestedParts = true;
    return state;
  });
  builder.addCase(asyncQueries.getSuggestedParts.rejected, (state) => {
    state.loading.suggestedParts = false;
    return state;
  });
  builder.addCase(asyncQueries.getSuggestedParts.fulfilled, (state, { payload }) => {
    state.loading.suggestedParts = false;
    state.suggestedParts[payload.variables.equipmentId] = [...payload.data.suggestedParts];
    return state;
  });
  builder.addCase(asyncQueries.getSymptoms.pending, (state) => {
    state.loading.symptoms = true;
    return state;
  });
  builder.addCase(asyncQueries.getSymptoms.rejected, (state) => {
    state.loading.symptoms = false;
    return state;
  });
  builder.addCase(asyncQueries.getSymptoms.fulfilled, (state, { payload: data }) => {
    state.loading.symptoms = false;
    state.symptoms = [...data.symptoms];
    return state;
  });
};
