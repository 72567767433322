import { useCallback, useEffect } from "react";

import { ServiceJob } from "operations/schema/schema";

import { JobDetailsSkeleton } from "components/job/JobDetailsSkeleton";
import { Outlet } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { setPage, setSelectedJob } from "store/root.store";
import { getCachePrefill } from "store/slices/cache.store";
import {
  getFiles,
  getJob,
  getNotes,
  getRelatedJobs,
  getVisits,
  selectJobLoaded,
} from "store/slices/jobs.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";
import {
  initializeVisit,
  selectVisitLoaded,
  setSelectedJob as setVisitSelectedJob,
} from "store/slices/jobs.store";

interface JobProviderProps {
  jobId: string | undefined;
  onCompleted?: (job: ServiceJob) => void;
  onError?: (e: Error) => void;
}

export default function JobProvider({ jobId, onError, onCompleted }: JobProviderProps) {
  const dispatch = useAppDispatch();
  const jobLoaded = useAppSelector(selectJobLoaded);
  const visitLoaded = useAppSelector(selectVisitLoaded);
  const { jobs } = useAppSelector((state) => state.jobs);
  const {
    jobCategories,
    symptoms,
    causes,
    loading: cacheLoading,
  } = useAppSelector((state) => state.cache);
  const { userVar } = useAppSelector((state) => state.user);

  const initializeJob = useCallback(async () => {
    await dispatch(getJob(onCompleted)).catch(() => {
      dispatch(addSnackbarMessage({ key: "GetJob-fail" }));
    });
    dispatch(getFiles());
    dispatch(getNotes());
    dispatch(getRelatedJobs());
    dispatch(getVisits()).catch(() => {
      dispatch(addSnackbarMessage({ key: "GetJobVisits-fail" }));
    });
  }, [dispatch, onCompleted]);

  const setJobId = useCallback(async () => {
    dispatch(setPage({ page: "jobs" }));
    await dispatch(setVisitSelectedJob({ jobId }));
    dispatch(setSelectedJob({ jobId }));
  }, [dispatch, jobId]);

  useEffect(() => {
    const job = jobId ? jobs[jobId] : null;
    if (!jobLoaded || !job || cacheLoading.prefill) return;
    dispatch(
      initializeVisit({
        job,
        jobCategories,
        symptoms,
        userVar: userVar!,
        hasCauses: !!causes.length,
      })
    );
  }, [
    cacheLoading.prefill,
    causes,
    dispatch,
    jobCategories,
    jobId,
    jobLoaded,
    jobs,
    symptoms,
    userVar,
  ]);

  useEffect(() => {
    if (!jobId) return;
    setJobId();
    initializeJob();
  }, [dispatch, initializeJob, jobId, setJobId]);

  useEffect(() => {
    dispatch(getCachePrefill({ force: false }));
  }, [dispatch]);

  if (!jobLoaded || !visitLoaded) {
    return <JobDetailsSkeleton />;
  }

  return <Outlet />;
}
