import { Add as AddIcon } from "@mui/icons-material";
import { Container, LinearProgress } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual } from "react-redux";
import { useLocation } from "react-router-dom";

import { isAbortError } from "helpers";
import { useEffectOnce } from "helpers/useEffectOnce";
import { JobFilter, JobFilterStorageKey } from "models/JobFilter";
import { StockStore } from "operations/schema/schema";
import { useAppDispatch, useAppSelector } from "store";
import { selectConnectionStatus, setPage, setSelectedJob } from "store/root.store";
import {
  getCachePrefill,
  getPartsCustomer,
  getPartsEngineer,
  getRequestableParts,
} from "store/slices/cache.store";
import {
  selectCreateJobOpen,
  setOpen as setOpenCreateJobDialog,
} from "store/slices/dialogs/createJob.store";
import { clearHistoryState, setHistorySelectedJob } from "store/slices/history.store";
import {
  getJobs,
  selectIncompleteJobs,
  selectJobFilterCount,
  selectLoadingJobs,
  setJobFilter,
} from "store/slices/jobs.store";
import { setAppBar } from "store/slices/menu.store";
import { clearPlannerState, setPlannerSelectedJob } from "store/slices/planner.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";
import { setSelectedJob as setVisitSelectedJob } from "store/slices/jobs.store";

import { FilterAppBar } from "components/FilterAppBar";
import StyledFabFixed from "components/StyledFabFixed";
import { JobCalendarView } from "components/job/JobCalendarView";
import { JobFilterDialog } from "components/job/JobFilterDialog";
import { JobList } from "components/job/JobList";
import { JobListSkeleton } from "components/job/JobListSkeleton";
import { NewJobsAlert } from "components/job/NewJobsAlert";
import { CreateJobDialog } from "./dialogs/CreateJobDialog";
import { ViewPreOrderPartsDialogue } from "./dialogs/ViewPreOrderedPartsDialog";

const sortingType = "job";

type JobsPageProps = {
  calendarView?: boolean;
};

export const JobsPage: FC<JobsPageProps> = (props) => {
  const { calendarView } = props;
  const dispatch = useAppDispatch();
  const isOnline = useAppSelector(selectConnectionStatus);
  const { userVar: userData, engineerSettings } = useAppSelector((s) => s.user);
  const { loading: cacheLoading } = useAppSelector((s) => s.cache);
  const jobs = useAppSelector(selectIncompleteJobs, shallowEqual);
  const loadingJobs = useAppSelector(selectLoadingJobs);
  const openCreateJobDialog = useAppSelector(selectCreateJobOpen);
  const intl = useIntl();
  const canCreateJob = engineerSettings?.canCreateJob && isOnline;
  const { state }: any = useLocation();
  const openFilter: boolean = state?.openFilter ?? false;
  const [filterDialogOpen, setFilterDialogOpen] = useState(openFilter);
  const filterCount = useAppSelector(selectJobFilterCount);

  

  useEffectOnce(() => {
    const jobFilterString = localStorage.getItem(JobFilterStorageKey);
    let storedJobFilter: JobFilter | null = null;
    if (jobFilterString) {
      storedJobFilter = JSON.parse(jobFilterString) as JobFilter;
      if (storedJobFilter.specificDate)
        storedJobFilter.specificDate = new Date(storedJobFilter.specificDate);
    }
    if (storedJobFilter != null) {
      dispatch(setJobFilter({ jobFilter: storedJobFilter, hideNewJobNotification: false }));
    }
  });

  useEffect(() => {
    dispatch(setPage({ page: "jobs" }));
    dispatch(setSelectedJob({ jobId: undefined }));
    dispatch(setVisitSelectedJob({ jobId: undefined }));
    dispatch(setPlannerSelectedJob({ jobId: undefined }));
    dispatch(setHistorySelectedJob({ jobId: undefined }));
    dispatch(clearPlannerState());
    dispatch(clearHistoryState());
    dispatch(getCachePrefill({ force: false }));
    dispatch(getPartsEngineer({ force: false }));
  }, [dispatch]);

  const dispatchRequestableParts = useCallback(() => {
    dispatch(
      getRequestableParts({
        location: {
          locationId: userData?.stockId,
          stockStore: StockStore.Engineer,
        },
        force: false,
      })
    );
  }, [dispatch, userData?.stockId]);

  useEffect(() => {
    dispatchRequestableParts();
  }, [dispatchRequestableParts]);

  const dispatchGetJobs = useCallback(
    (override: boolean = false) => {
      dispatch(getJobs({ force: override }))
        .then(unwrapResult)
        .then(() => {
          dispatch(getPartsCustomer());
        })
        .catch((e) => {
          if (isAbortError(e)) return;
          dispatch(addSnackbarMessage({ key: "GetJobs-fail" }));
        });
    },
    [dispatch]
  );

  useEffect(() => {
    dispatchGetJobs(false);
  }, [dispatchGetJobs]);

  useEffect(() => {
    dispatch(
      setAppBar({
        title: intl.formatMessage({ id: "menu.myJobs" }),
        sortingType,
        refetchEnabled: true,
        calendarView: calendarView,
        unusedPartsButton: true,
      })
    );
  }, [dispatch, intl, calendarView]);

  return loadingJobs ||
    cacheLoading.prefill ||
    cacheLoading.engineerParts ||
    cacheLoading.customerParts ||
    cacheLoading.requestableParts ? (
    <Container data-testid="jobsPageContainer">
      <LinearProgress />
      <JobListSkeleton showBorder />
    </Container>
  ) : (
    <>
      <FilterAppBar
        onClick={setFilterDialogOpen}
        foundCount={jobs.length}
        filterCount={filterCount}
        type="jobs"
        marginTop="57px"
      />
      <NewJobsAlert />
      <Container data-testid="jobsPageContainer">
        {calendarView && (
          <JobCalendarView
            jobs={jobs}
            loading={loadingJobs}
            refetchJobs={dispatchGetJobs}
            toLink="/jobs"
          />
        )}
        {!calendarView && (
          <JobList
            jobs={jobs}
            loading={loadingJobs}
            refetchJobs={dispatchGetJobs}
            toLink="/jobs"
            sortingType={sortingType}
            showBorder
          />
        )}
        {canCreateJob && !openCreateJobDialog && (
          <StyledFabFixed
            color="primary"
            component="span"
            className="e2e-create-job-fab-button"
            data-testid="createJobButton"
            onClick={() => dispatch(setOpenCreateJobDialog({ open: true }))}
          >
            <AddIcon />
          </StyledFabFixed>
        )}
        {canCreateJob && openCreateJobDialog && <CreateJobDialog />}
        <JobFilterDialog
          open={filterDialogOpen}
          setOpen={setFilterDialogOpen}
          onClose={(dirty) => {
            dispatchGetJobs(dirty);
          }}
        />
        <ViewPreOrderPartsDialogue/>
      </Container>
    </>
  );
};