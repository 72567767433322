import { CustomerType, EngineerType, JobSymptomType } from "operations/schema/schema";
import { CustomerSimple } from "./CustomerSimple";

export const PlannerFilterStorageKey = "plannerFilter";

export type PlannerFilterDateTypes = "week" | "1month" | "3month" | "specificDate";
export type PlannerFilter = {
  typeDate: PlannerFilterDateTypes;
  jobId: string | null;
  selectedEngineers: EngineerType[];
  selectedSymptoms: JobSymptomType[];
  specificDate: Date | null;
  serviceRegion: string | null;
  customer: CustomerSimple | CustomerType | null;
  city: string | null;
  postalCode: string | null;
};

export const defaultPlannerFilter: PlannerFilter = {
  typeDate: "week",
  jobId: null,
  selectedEngineers: [],
  selectedSymptoms: [],
  specificDate: null,
  serviceRegion: null,
  customer: null,
  city: null,
  postalCode: null,
};

const keysToExclude = ["specificDate", "typeDate"] as const;
type FilterKeys = (keyof Omit<typeof defaultPlannerFilter, (typeof keysToExclude)[number]>)[];
export const PlannerFilterKeys: FilterKeys = Object.keys(defaultPlannerFilter).filter(
  (x) => !keysToExclude.includes(x as any)
) as FilterKeys;
