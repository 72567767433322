import { DriveEta as DriveEtaIcon } from "@mui/icons-material";
import { unwrapResult } from "@reduxjs/toolkit";
import PrimaryButton from "components/PrimaryButton";
import { isAbortError, isEmpty } from "helpers";
import { FC, memo } from "react";
import { FormattedMessage } from "react-intl";
import { useAppDispatch, useAppSelector } from "store";
import { addSnackbarMessage } from "store/slices/snackbar.store";
import {
  addTravel,
  selectSelectedJob,
  selectSelectedJobVisit,
  startVisitTravel,
  stopTravel,
  updateVisitTravel,
} from "store/slices/jobs.store";

interface TravelButtonProps {
  onClick: () => void;
}

export const TravelButton: FC<TravelButtonProps> = memo((props) => {
  const { onClick } = props;
  const job = useAppSelector(selectSelectedJob);
  const visit = useAppSelector(selectSelectedJobVisit);
  const dispatch = useAppDispatch();
  const lastTravelIndex = visit.travelTimes.length - 1;
  const isStartTravel =
    isEmpty(visit.travelTimes) || lastTravelIndex === -1 || !visit.travelTimes[0]?.startTime;
  const isStopTravel =
    !isStartTravel && lastTravelIndex !== 0 && !visit.travelTimes[lastTravelIndex]?.stopTime;

  const doTravel = async () => {
    if (isStartTravel) {
      await dispatch(addTravel());
      dispatch(startVisitTravel({ jobId: job.id }))
        .then(unwrapResult)
        .then(({ queued }) => {
          if (queued) {
            dispatch(addSnackbarMessage({ key: "UpdateTravel-stored" }));
          } else {
            dispatch(addSnackbarMessage({ key: "StartTravel-success" }));
          }
        })
        .catch((e) => {
          if (isAbortError(e)) return;
          dispatch(addSnackbarMessage({ key: "StartTravel-fail" }));
        });
    }

    if (isStopTravel) {
      await dispatch(stopTravel());
      dispatchUpdateVisitTravel();
    }

    if (!isStartTravel && !isStopTravel && lastTravelIndex !== 0) {
      await dispatch(addTravel());
      dispatchUpdateVisitTravel();
    }

    onClick();
  };

  const dispatchUpdateVisitTravel = () => {
    dispatch(updateVisitTravel({ jobId: job.id }))
      .then(unwrapResult)
      .then(({ queued }) => {
        if (queued) {
          dispatch(addSnackbarMessage({ key: "UpdateTravel-stored" }));
        } else {
          dispatch(addSnackbarMessage({ key: "UpdateTravel-success" }));
        }
      })
      .catch((e) => {
        if (isAbortError(e)) return;
        dispatch(addSnackbarMessage({ key: "UpdateTravel-fail" }));
      });
  };

  return (
    <PrimaryButton
      data-testid="travelButton"
      onClick={doTravel}
      startIcon={<DriveEtaIcon />}
      style={{ textTransform: "none" }}
    >
      {isStartTravel ? (
        <FormattedMessage id="visit.addTravelTime" />
      ) : isStopTravel ? (
        <FormattedMessage id="visit.stopTravel" />
      ) : (
        <FormattedMessage id="visit.continueTravel" />
      )}
    </PrimaryButton>
  );
});
