import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { graphqlRequest } from "context/graphql/functions";
import { differenceInHours } from "date-fns";
import { addPeriod, toDateString } from "helpers";
import { Jobs } from "models/Job";
import { GetEquipmentQueryVariables, ServiceJob } from "operations/schema/schema";
import { AppAsyncThunkConfig } from "store";
import { State } from "./jobs.store";
import { initializeJob } from "helpers/initializeJob";

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

export const asyncQueries = {
  getJob: createAppAsyncThunk(
    "jobs/getJob",
    async (
      onCompleted: ((job: ServiceJob) => void) | undefined,
      { getState, rejectWithValue, extra: { sdk } }
    ) => {
      let { jobs, cache, user } = getState();
      const selectedJobId = jobs.selectedJobId;
      if (!selectedJobId) return rejectWithValue("No selected job");
      const { data, errors } = await graphqlRequest(sdk.getJob, {
        variables: {
          id: selectedJobId,
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.job) return rejectWithValue("something went wrong");
      return { data, selectedJobId, onCompleted, cache, user };
    }
  ),
  getJobs: createAppAsyncThunk(
    "jobs/getJobs",
    async (props: { force?: boolean }, { getState, rejectWithValue, extra: { sdk } }) => {
      const {
        jobs: { lastLoaded, jobFilter },
        cache,
        user,
      } = getState();
      if (!props.force && lastLoaded && differenceInHours(new Date(lastLoaded), new Date()) < 1) {
        return rejectWithValue("Abort");
      }
      let selectedSymptoms = jobFilter.selectedSymptoms.map((x) => x?.code) as string[];
      const { data, errors } = await graphqlRequest(sdk.getJobs, {
        variables: {
          max: 100,
          jobFilter: {
            jobId: jobFilter.jobId,
            toDate: toDateString(addPeriod(jobFilter.typeDate)),
            plannedDate: toDateString(jobFilter.specificDate),
            symptomIds: selectedSymptoms,
            serviceRegion: jobFilter.serviceRegion,
            customerId: jobFilter.customer?.id,
            city: jobFilter.city,
            postalCode: jobFilter.postalCode,
          },
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.jobs) return rejectWithValue("something went wrong");
      return { data, cache, user };
    }
  ),
  getCustomers: createAppAsyncThunk(
    "jobs/getCustomers",
    async (variables: { nameFilter: string }, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getCustomersSimple, {
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.customers) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getFiles: createAppAsyncThunk(
    "jobs/getFiles",
    async (_, { getState, rejectWithValue, extra: { sdk } }) => {
      let { jobs } = getState();
      const selectedJobId = jobs.selectedJobId;
      if (!selectedJobId) return rejectWithValue("No selected job");
      const { data, errors } = await graphqlRequest(sdk.getFiles, {
        variables: {
          jobId: selectedJobId,
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.files) return rejectWithValue("something went wrong");
      return { data, selectedJobId };
    }
  ),
  getNotes: createAppAsyncThunk(
    "jobs/getNotes",
    async (_, { getState, rejectWithValue, extra: { sdk } }) => {
      let { jobs } = getState();
      const selectedJobId = jobs.selectedJobId;
      if (!selectedJobId) return rejectWithValue("No selected job");
      const selectedJob = jobs.jobs[selectedJobId];
      const { data, errors } = await graphqlRequest(sdk.getWorkNotes, {
        variables: {
          workNoteArgs: {
            jobId: selectedJobId,
            contractId: selectedJob.contractId,
            customerId: selectedJob.customer?.id,
            equipmentId: selectedJob.equipment?.id,
          },
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.workNotes) return rejectWithValue("something went wrong");
      return { data, selectedJobId };
    }
  ),
  getRelatedJobs: createAppAsyncThunk(
    "jobs/getRelatedJobs",
    async (_, { getState, rejectWithValue, extra: { sdk } }) => {
      let { jobs } = getState();
      const selectedJobId = jobs.selectedJobId;
      if (!selectedJobId) return rejectWithValue("No selected job");
      const selectedJob = jobs.jobs[selectedJobId];
      const { data, errors } = await graphqlRequest(sdk.getRelatedJobs, {
        variables: {
          max: 100,
          customerId: selectedJob.customer?.id,
          equipmentId: selectedJob.equipment?.id,
          jobId: selectedJobId,
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.relatedJobs) return rejectWithValue("something went wrong");
      return { data, selectedJobId };
    }
  ),
  getVisits: createAppAsyncThunk(
    "jobs/getVisits",
    async (_, { getState, rejectWithValue, extra: { sdk } }) => {
      let { jobs } = getState();
      const selectedJobId = jobs.selectedJobId;
      if (!selectedJobId) return rejectWithValue("No selected job");
      const { data, errors } = await graphqlRequest(sdk.getJobVisits, {
        variables: {
          max: 20,
          jobId: selectedJobId,
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.jobVisits) return rejectWithValue("something went wrong");
      return { data, selectedJobId };
    }
  ),
  //ChangeJobEquipmentDialog
  getEquipment: createAppAsyncThunk(
    "jobs/getEquipment",
    async (variables: GetEquipmentQueryVariables, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getEquipment, {
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.equipment) return rejectWithValue("something went wrong");
      return data;
    }
  ),
};

export const queryBuilder = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(asyncQueries.getJob.pending, (state) => {
    state.loadingJob = true;
    return state;
  });
  builder.addCase(asyncQueries.getJob.rejected, (state) => {
    state.loadingJob = false;
    return state;
  });
  builder.addCase(
    asyncQueries.getJob.fulfilled,
    (state, { payload: { data, selectedJobId, cache, user, onCompleted } }) => {
      let { job } = data;
      if (!selectedJobId || !job) return state;
      /**
       * While this "if" shouldn't be necessary, selected job can be undefined
       * if you start the app or reload while viewing a job
       */
      if (!state.jobs[selectedJobId]) {
        state.jobs[selectedJobId] = {
          ...job,
          files: [],
          workNotes: [],
          visits: [],
          relatedJobs: [],
        };
      } else {
        state.jobs[selectedJobId] = {
          ...state.jobs[selectedJobId],
          ...job,
          files: state.jobs[selectedJobId].files || [],
          workNotes: state.jobs[selectedJobId].workNotes || [],
          visits: state.jobs[selectedJobId].visits || [],
          relatedJobs: state.jobs[selectedJobId].relatedJobs || [],
        };
      }
      
      if (!state.jobVisits[job.id]) {
        initializeJob(
          state,
          state.jobs[selectedJobId],
          cache.causes.length > 0,
          cache.jobCategories,
          cache.symptoms,
          user.userVar!
        );
      }

      state.loadingJob = false;
      if (onCompleted) onCompleted(job);
      return state;
    }
  );
  builder.addCase(asyncQueries.getJobs.pending, (state, { meta }) => {
    if (meta.queued) return state;
    state.loadingJobs = true;
    return state;
  });
  builder.addCase(asyncQueries.getJobs.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    state.loadingJobs = false;
    return state;
  });
  builder.addCase(asyncQueries.getJobs.fulfilled, (state, { payload: { data, cache, user } }) => {
    let oldJobs = state.jobs;
    let oldJobIds = Object.keys(oldJobs);
    let serviceJobs = data.jobs;
    if (serviceJobs.length > 0) {
      let newJobs = serviceJobs.reduce((obj, next) => {
        if (!oldJobs[next.id]) {
          obj[next.id] = {
            ...next,
            files: [],
            workNotes: [],
            visits: [],
            relatedJobs: [],
          };
        } else {
          obj[next.id] = {
            ...oldJobs[next.id],
            ...next,
            files: oldJobs[next.id].files || [],
            workNotes: oldJobs[next.id].workNotes || [],
            visits: oldJobs[next.id].visits || [],
            relatedJobs: oldJobs[next.id].relatedJobs || [],
          };
        }
        const job = obj[next.id];
        if (!state.jobVisits[job.id]) {
                initializeJob(
                  state,
                  job,
                  cache.causes.length > 0,
                  cache.jobCategories,
                  cache.symptoms,
                  user.userVar!
                );
              }
        return obj;
      }, {} as Jobs);

      if (state.hideNewJobNotification) {
        state.newJobIds = [];
      } else {
        let newJobIds = Object.keys(newJobs);
        state.newJobIds = oldJobIds.length ? newJobIds.filter((x) => !oldJobIds.includes(x)) : [];
      }
      state.hideNewJobNotification = false;

      state.jobs = newJobs;
    } else {
      state.jobs = {};
    }
    state.loadingJobs = false;
    state.lastLoaded = new Date().toISOString();
    return state;
  });
  builder.addCase(asyncQueries.getCustomers.pending, (state) => {
    state.filterOptions.loadingCustomers = true;
    return state;
  });
  builder.addCase(asyncQueries.getCustomers.rejected, (state) => {
    state.filterOptions.loadingCustomers = false;
    return state;
  });
  builder.addCase(asyncQueries.getCustomers.fulfilled, (state, { payload: { customers } }) => {
    state.filterOptions.loadingCustomers = false;
    state.filterOptions.customers = [...customers];
    return state;
  });
  builder.addCase(
    asyncQueries.getFiles.fulfilled,
    (state, { payload: { data, selectedJobId } }) => {
      if (!selectedJobId) return state;
      state.jobs[selectedJobId].files = [...data.files];
      return state;
    }
  );
  builder.addCase(
    asyncQueries.getNotes.fulfilled,
    (state, { payload: { data, selectedJobId } }) => {
      if (!selectedJobId) return state;
      state.jobs[selectedJobId].workNotes = [...data.workNotes];
      return state;
    }
  );
  builder.addCase(asyncQueries.getRelatedJobs.pending, (state) => {
    state.loadingRelatedJobs = true;
    return state;
  });
  builder.addCase(asyncQueries.getRelatedJobs.rejected, (state) => {
    state.loadingRelatedJobs = false;
    return state;
  });
  builder.addCase(
    asyncQueries.getRelatedJobs.fulfilled,
    (state, { payload: { data, selectedJobId } }) => {
      if (!selectedJobId) return state;
      state.jobs[selectedJobId].relatedJobs = [...data.relatedJobs];
      state.loadingRelatedJobs = false;
      return state;
    }
  );
  builder.addCase(
    asyncQueries.getVisits.fulfilled,
    (state, { payload: { data, selectedJobId } }) => {
      if (!selectedJobId) return state;
      state.jobs[selectedJobId].visits = [...data.jobVisits];
      return state;
    }
  );
  // ChangeJobEquipmentDialog
  builder.addCase(asyncQueries.getEquipment.pending, (state) => {
    state.changeJobEquipment.loadingEquipments = true;
    return state;
  });
  builder.addCase(asyncQueries.getEquipment.rejected, (state) => {
    state.changeJobEquipment.loadingEquipments = false;
    return state;
  });
  builder.addCase(asyncQueries.getEquipment.fulfilled, (state, { payload: { equipment } }) => {
    state.changeJobEquipment.loadingEquipments = false;
    state.changeJobEquipment.equipments = [...equipment];
    return state;
  });
};
