import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { Auth0State, getInitialAuth0LoginState } from "models/auth0LoginState";
import { AuthState, getInitialAuthState } from "models/authState";
import { Flag } from "models/FlagTypes";
import { getDefaultUserLanguage, Language } from "models/Setting";
import { getInitialUserState, User } from "models/user";
import { EngineerSettings, UserEndpointResult, UserSettings } from "operations/schema/schema";
import { RootState } from "store";
import { asyncMutations, mutationBuilder } from "./user.mutations";
import { asyncQueries, queryBuilder } from "./user.queries";

// Interface for state
export interface State {
  authVar: AuthState;
  auth0Var: Auth0State;
  userVar: User | null;
  userEndpoints: UserEndpointResult;
  userSettings: UserSettings;
  engineerSettings: EngineerSettings;
  languageVar: Language;
  featureFlagVar: {
    isActive: boolean;
    name: string;
  }[];
}

// Interface for store actions
interface Actions extends SliceCaseReducers<State> {
  setAuth: (state: State, action: PayloadAction<AuthState>) => State;
  setAuth0: (state: State, action: PayloadAction<Auth0State>) => State;
  setUser: (state: State, action: PayloadAction<User>) => State;
  setUserEndpoints: (state: State, action: PayloadAction<UserEndpointResult>) => State;
  setUserSettings: (state: State, action: PayloadAction<UserSettings>) => State;
  setLanguage: (state: State, action: PayloadAction<Language>) => State;
  setEngineerSettings: (state: State, action: PayloadAction<EngineerSettings>) => State;
  setLoggedOut: (state: State) => State;
}
/** Example function interface
 * setOpen: (
 *    state: State,
 *    action: PayloadAction<{ dialogName: string; open: boolean }>
 * ) => State;
 */

// Interface for store selectors (if necessary)
interface Selectors {
  isFlagEnabled: (state: RootState, flagName: Flag) => boolean;
  selectAuth: (state: RootState) => AuthState;
  selectAuth0: (state: RootState) => Auth0State;
  selectEngineerSettings: (state: RootState) => EngineerSettings;
  selectLanguage: (state: RootState) => Language;
  selectUser: (state: RootState) => User | null;
  selectUserEndpoints: (state: RootState) => UserEndpointResult;
  selectUserSettings: (state: RootState) => UserSettings;
}
/** Example function interface
 * selectAuth: (
 *    state: RootState
 * ) => AuthState;
 */

// Definition of actual (initial) state
const initialState: State = {
  authVar: getInitialAuthState(),
  auth0Var: getInitialAuth0LoginState(),
  userVar: getInitialUserState(),
  languageVar: getDefaultUserLanguage(),
  featureFlagVar: [],
  userEndpoints: {
    email: "",
    useMultipleEndpoints: false,
    userEndpoints: [],
  },
  userSettings: {
    email: "",
    useMultipleEndpoints: false,
    userEndpoints: [],
  },
  engineerSettings: {
    canChangeEquipmentOnTicket: false,
    canChangeServiceLevel: false,
    canCreateJob: false,
    canEditCustomEquipmentProps: false,
    canUseTransitManager: false,
    isEquipmentMandatory: false,
    isSupervisor: false,
    requireEngineerSignature: false,
  },
};

// Definition of actual actions
const actions: Actions = {
  setAuth: (state, { payload }) => {
    state.authVar = payload;
    return state;
  },
  setAuth0: (state, { payload }) => {
    state.auth0Var = payload;
    return state;
  },
  setUser: (state, { payload }) => {
    state.userVar = payload;
    return state;
  },
  setUserEndpoints: (state, { payload }) => {
    state.userEndpoints = payload;
    return state;
  },
  setUserSettings: (state, { payload }) => {
    state.userSettings = payload;
    return state;
  },
  setLanguage: (state, { payload }) => {
    state.languageVar = payload;
    return state;
  },
  setEngineerSettings: (state, { payload }) => {
    state.engineerSettings = payload;
    return state;
  },
  setLoggedOut: (state) => {
    state.userVar = null;
    state.authVar = { isLoggedIn: false };
    return state;
  },
};
/** Example function
 * setOpen: (state, { payload: { dialogName, open } }) => {
 *    state[dialogName] = open;
 *    return state;
 * },
 */

// Definition of actual selectors
const selectors: Selectors = {
  isFlagEnabled: ({ user }, flagName) =>
    !!user.featureFlagVar.find((f) => f.name === flagName)?.isActive,
  selectAuth: ({ user }) => user.authVar,
  selectAuth0: ({ user }) => user.auth0Var,
  selectLanguage: ({ user }) => user.languageVar,
  selectUser: ({ user }) => user.userVar,
  selectUserEndpoints: ({ user }) => user.userEndpoints,
  selectUserSettings: ({ user }) => user.userSettings,
  selectEngineerSettings: ({ user }) => user.engineerSettings,
};
/** Example function
 * selectAuth: ({user}) => user.authVar
 */

// * [storename]: Name of store with lowercase letters
const storeBase = createSlice<State, Actions>({
  name: "user",
  initialState,
  reducers: actions,
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    queryBuilder(builder);
    mutationBuilder(builder);
  },
});

// To be imported and added in store/reducers
export default storeBase.reducer;
export const {
  setAuth,
  setAuth0,
  setUser,
  setLanguage,
  setUserSettings,
  setUserEndpoints,
  setLoggedOut,
} = storeBase.actions;
export const {
  getPublicFeatureFlags,
  getFeatureFlags,
  getUserEndpoints,
  getUserSettings,
  getEngineerSettings,
} = asyncQueries;

export const {
  preLogin,
  loginAuth0,
  logout,
  updateUserConsent,
  updateUserSettings,
  updateEngineerMetadata,
} = asyncMutations;

export const {
  isFlagEnabled,
  selectAuth,
  selectAuth0,
  selectEngineerSettings,
  selectLanguage,
  selectUser,
  selectUserEndpoints,
  selectUserSettings,
} = selectors;
