import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { graphqlRequest } from "context/graphql/functions";
import { addPeriod, toDateString } from "helpers";
import { Jobs } from "models/Job";
import { ServiceJob } from "operations/schema/schema";
import { AppAsyncThunkConfig } from "store";
import { State } from "./planner.store";

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

export const asyncQueries = {
  getPlannerJob: createAppAsyncThunk(
    "planner/getPlannerJob",
    async (
      onCompleted: ((job: ServiceJob) => void) | undefined,
      { getState, rejectWithValue, extra: { sdk } }
    ) => {
      let { root } = getState();
      const selectedJobId = root.selectedJobId;
      if (!selectedJobId) return rejectWithValue("No selected job");
      const { data, errors } = await graphqlRequest(sdk.getJob, {
        variables: {
          id: selectedJobId,
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.job) return rejectWithValue("something went wrong");
      return { data, selectedJobId, onCompleted };
    }
  ),
  getPlannerJobs: createAppAsyncThunk(
    "planner/getPlannerJobs",
    async (_, { getState, rejectWithValue, extra: { sdk } }) => {
      const filter = getState().planner.plannerFilter;
      const { data, errors } = await graphqlRequest(sdk.getJobsPlanner, {
        variables: {
          plannerFilter: {
            jobId: filter.jobId,
            toDate: toDateString(addPeriod(filter.typeDate)),
            responseDate: toDateString(filter.specificDate),
            engineerIds: filter.selectedEngineers.map((x) => x?.id),
            symptomIds: filter.selectedSymptoms.map((x) => x?.code) as string[],
            serviceRegion: filter.serviceRegion,
            customerId: filter.customer?.id,
            city: filter.city,
            postalCode: filter.postalCode,
          },
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.jobsPlanner) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getFiles: createAppAsyncThunk(
    "planner/getFiles",
    async (_, { getState, rejectWithValue, extra: { sdk } }) => {
      let { root } = getState();
      const selectedJobId = root.selectedJobId;
      if (!selectedJobId) return rejectWithValue("No selected job");
      const { data, errors } = await graphqlRequest(sdk.getFiles, {
        variables: {
          jobId: selectedJobId,
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.files) return rejectWithValue("something went wrong");
      return { data, selectedJobId };
    }
  ),
  getNotes: createAppAsyncThunk(
    "planner/getNotes",
    async (_, { getState, rejectWithValue, extra: { sdk } }) => {
      let { planner, root } = getState();
      const selectedJobId = root.selectedJobId;
      if (!selectedJobId) return rejectWithValue("No selected job");
      const selectedJob = planner.jobs[selectedJobId];
      const { data, errors } = await graphqlRequest(sdk.getWorkNotes, {
        variables: {
          workNoteArgs: {
            jobId: selectedJobId,
            contractId: selectedJob.contractId,
            customerId: selectedJob.customer?.id,
            equipmentId: selectedJob.equipment?.id,
          },
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.workNotes) return rejectWithValue("something went wrong");
      return { data, selectedJobId };
    }
  ),
  getRelatedJobs: createAppAsyncThunk(
    "planner/getRelatedJobs",
    async (_, { getState, rejectWithValue, extra: { sdk } }) => {
      let { planner, root } = getState();
      const selectedJobId = root.selectedJobId;
      if (!selectedJobId) return rejectWithValue("No selected job");
      const selectedJob = planner.jobs[selectedJobId];
      const { data, errors } = await graphqlRequest(sdk.getRelatedJobs, {
        variables: {
          max: 100,
          customerId: selectedJob.customer?.id,
          equipmentId: selectedJob.equipment?.id,
          jobId: selectedJobId,
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.relatedJobs) return rejectWithValue("something went wrong");
      return { data, selectedJobId };
    }
  ),
  getVisits: createAppAsyncThunk(
    "planner/getVisits",
    async (_, { getState, rejectWithValue, extra: { sdk } }) => {
      let { root } = getState();
      const selectedJobId = root.selectedJobId;
      if (!selectedJobId) return rejectWithValue("No selected job");
      const { data, errors } = await graphqlRequest(sdk.getJobVisits, {
        variables: {
          max: 20,
          jobId: selectedJobId,
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.jobVisits) return rejectWithValue("something went wrong");
      return { data, selectedJobId };
    }
  ),
  getEngineers: createAppAsyncThunk(
    "planner/getEngineers",
    async (_, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getEngineers);
      if (errors) return rejectWithValue(errors);
      if (!data?.engineers) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getCustomers: createAppAsyncThunk(
    "planner/getCustomers",
    async (variables: { nameFilter: string }, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getCustomersSimple, {
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.customers) return rejectWithValue("something went wrong");
      return data;
    }
  ),
};

export const queryBuilder = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(asyncQueries.getPlannerJob.pending, (state) => {
    state.loadingJob = true;
    return state;
  });
  builder.addCase(asyncQueries.getPlannerJob.rejected, (state) => {
    state.loadingJob = false;
    return state;
  });
  builder.addCase(
    asyncQueries.getPlannerJob.fulfilled,
    (state, { payload: { data, selectedJobId, onCompleted } }) => {
      let { job } = data;
      if (!selectedJobId || !job) return state;
      /**
       * While this "if" shouldn't be necessary, selected job can be undefined
       * if you start the app or reload while viewing a job
       */
      if (!state.jobs[selectedJobId]) {
        state.jobs[selectedJobId] = {
          ...job,
          files: [],
          workNotes: [],
          visits: [],
          relatedJobs: [],
        };
      } else {
        state.jobs[selectedJobId] = {
          ...state.jobs[selectedJobId],
          ...job,
          files: state.jobs[selectedJobId].files || [],
          workNotes: state.jobs[selectedJobId].workNotes || [],
          visits: state.jobs[selectedJobId].visits || [],
          relatedJobs: state.jobs[selectedJobId].relatedJobs || [],
        };
      }
      state.loadingJob = false;
      if (onCompleted) onCompleted(job);
      return state;
    }
  );
  builder.addCase(asyncQueries.getPlannerJobs.pending, (state) => {
    state.loadingJobs = true;
    return state;
  });
  builder.addCase(asyncQueries.getPlannerJobs.rejected, (state) => {
    state.loadingJobs = false;
    return state;
  });
  builder.addCase(asyncQueries.getPlannerJobs.fulfilled, (state, { payload: data }) => {
    let serviceJobs = data.jobsPlanner;
    let newJobs = serviceJobs.reduce((obj, next) => {
      obj[next.id] = {
        ...next,
        files: [],
        workNotes: [],
        visits: [],
        relatedJobs: [],
      };

      return obj;
    }, {} as Jobs);

    state.jobs = newJobs;
    state.loadingJobs = false;
    return state;
  });
  builder.addCase(
    asyncQueries.getFiles.fulfilled,
    (state, { payload: { data, selectedJobId } }) => {
      if (!selectedJobId) return state;
      state.jobs[selectedJobId].files = [...data.files];
      return state;
    }
  );
  builder.addCase(
    asyncQueries.getNotes.fulfilled,
    (state, { payload: { data, selectedJobId } }) => {
      if (!selectedJobId) return state;
      state.jobs[selectedJobId].workNotes = [...data.workNotes];
      return state;
    }
  );
  builder.addCase(asyncQueries.getRelatedJobs.pending, (state) => {
    state.loadingRelatedJobs = true;
    return state;
  });
  builder.addCase(asyncQueries.getRelatedJobs.rejected, (state) => {
    state.loadingRelatedJobs = false;
    return state;
  });
  builder.addCase(
    asyncQueries.getRelatedJobs.fulfilled,
    (state, { payload: { data, selectedJobId } }) => {
      if (!selectedJobId) return state;
      state.jobs[selectedJobId].relatedJobs = [...data.relatedJobs];
      state.loadingRelatedJobs = false;
      return state;
    }
  );
  builder.addCase(
    asyncQueries.getVisits.fulfilled,
    (state, { payload: { data, selectedJobId } }) => {
      if (!selectedJobId) return state;
      state.jobs[selectedJobId].visits = [...data.jobVisits];
      return state;
    }
  );
  builder.addCase(asyncQueries.getEngineers.pending, (state) => {
    state.loadingEngineers = true;
    return state;
  });
  builder.addCase(asyncQueries.getEngineers.rejected, (state, { payload: errors }) => {
    state.loadingEngineers = false;
    return state;
  });
  builder.addCase(asyncQueries.getEngineers.fulfilled, (state, { payload: { engineers } }) => {
    state.loadingEngineers = false;
    state.engineers = [...engineers];
    return state;
  });
  builder.addCase(asyncQueries.getCustomers.pending, (state) => {
    state.loadingCustomers = true;
    return state;
  });
  builder.addCase(asyncQueries.getCustomers.rejected, (state) => {
    state.loadingCustomers = false;
    return state;
  });
  builder.addCase(asyncQueries.getCustomers.fulfilled, (state, { payload: { customers } }) => {
    state.loadingCustomers = false;
    state.customers = [...customers];
    return state;
  });
};
