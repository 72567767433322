import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Autocomplete, FormHelperText, Grid, styled } from "@mui/material";
import { FC, useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import { ActionAutocomplete } from "components/ActionAutocomplete";
import StyledTextField from "components/StyledTextField";
import { findMatchingCode } from "helpers";
import { CauseType } from "operations/schema/schema";
import { useAppDispatch, useAppSelector } from "store";
import {
  selectSelectedJob,
  selectSelectedJobVisit,
  setVisitValue,
  validateVisit,
} from "store/slices/jobs.store";

const StyledGrid = styled(Grid)(({ theme }) => ({
  color: theme.palette.info.main,
  fontWeight: "bold",
  margin: `${theme.spacing(1)} 0`,
  cursor: "pointer",
}));

export const VisitDetails: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectSelectedJob);
  const { errors, actionId1, actionId2, actionId3, solutionDescription, causeId } =
    useAppSelector(selectSelectedJobVisit);
  const { causes, actions, loading: cacheLoading } = useAppSelector((s) => s.cache);
  const filteredJobCauses = causes?.filter((c) => c.typeId === job.typeId || c.typeId == null);
  const filteredJobActions = actions?.filter((a) => a.typeId === job.typeId || a.typeId == null);

  const justOnInitialMount = useRef(false); // skip use effect on mount
  useEffect(() => {
    if (!justOnInitialMount.current) {
      dispatch(validateVisit());
      justOnInitialMount.current = true;
    }
  }, [dispatch]);

  const copyText = () => {
    const actionText1 = filteredJobActions?.find((a) => a?.id === actionId1)?.name;
    const actionText2 = filteredJobActions?.find((a) => a?.id === actionId2)?.name;
    const actionText3 = filteredJobActions?.find((a) => a?.id === actionId3)?.name;

    const actionString = [actionText1, actionText2, actionText3].filter((a) => a ?? a).join(", ");

    dispatch(
      setVisitValue({
        key: "solutionDescription",
        value: [solutionDescription, actionString].join(" "),
      })
    );
  };

  return (
    <Grid container direction="column" spacing={1} width="100%" data-testid="VisitDetailsContainer">
      {filteredJobCauses?.length === 0 ? null : (
        <Grid item mt={1}>
          <Autocomplete
            fullWidth
            options={filteredJobCauses || []}
            getOptionLabel={(cause: CauseType) => `${cause.code} - ${cause.name}`}
            onChange={(_, value: CauseType | null) => {
              dispatch(setVisitValue({ key: "causeId", value: value?.id }));
            }}
            value={findMatchingCode(filteredJobCauses, causeId)}
            data-testid="VisitDetails-CauseAutocomplete"
            renderInput={(params) => (
              <StyledTextField
                error={!!errors?.causeId}
                name="causeId"
                className="causeId"
                {...params}
                label={intl.formatMessage({ id: "job.cause" })}
                variant="outlined"
              />
            )}
          />
          <FormHelperText error>{errors?.causeId}</FormHelperText>
        </Grid>
      )}
      <ActionAutocomplete actions={filteredJobActions ?? []} loading={cacheLoading.prefill} />
      <StyledGrid
        item
        container
        alignItems="center"
        spacing={1}
        onClick={copyText}
        data-testid="VisitDetails-CopyAction"
      >
        <Grid item>
          <ContentCopyIcon />
        </Grid>
        <Grid item>
          {intl.formatMessage({
            id: "visit.copyActionText",
          })}
        </Grid>
      </StyledGrid>
      <Grid item pb={1.5}>
        <StyledTextField
          id="solutionDescription"
          name="solutionDescription"
          label={intl.formatMessage({ id: "job.solutionDescription" })}
          value={solutionDescription}
          onChange={({ target: { value } }) => {
            dispatch(setVisitValue({ key: "solutionDescription", value }));
          }}
          error={Boolean(errors.solutionDescription)}
          helperText={errors.solutionDescription}
          multiline
          rows={10}
          data-testid="VisitDetails-SolutionDescription"
        />
      </Grid>
    </Grid>
  );
};
