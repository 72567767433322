import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { fromLocal } from "helpers/fromLocal";
import {
  Group,
  GroupingKey,
  HistoryGroupingKey,
  historyGroupingKeys,
  JobGroupingKey,
  jobGroupingKeys,
} from "helpers/groupers";
import {
  HistorySortingKey,
  historySortingKeys,
  JobSortingKey,
  jobSortingKeys,
  Sorting,
  SortingKey,
  SortingPage,
} from "helpers/sorters";
// import { RootState } from "store";

const defaultJobSorting: Sorting<JobSortingKey> = {
  key: "plannedDate",
  order: "desc",
};
const defaultHistorySorting: Sorting<HistorySortingKey> = {
  key: "completedDate",
  order: "asc",
};
const defaultJobGroup: Group<JobGroupingKey> = {
  key: "plannedDate",
  order: "asc",
};
const defaultHistoryGroup: Group<HistoryGroupingKey> = {
  key: "completedDate",
  order: "asc",
};

type BackActionTypes = "ClearNewJobIds" | undefined;

// Interface for state
type SortingVar = {
  [x in SortingPage]: {
    sorting: Sorting<SortingKey>;
    grouping: Group<GroupingKey>;
  };
};
type SortingKeys = {
  [x in SortingPage]: readonly SortingKey[];
};
type GroupingKeys = {
  [x in SortingPage]: readonly GroupingKey[];
};
export interface State {
  appBar: {
    title: string;
    sortingType?: SortingPage;
    goingBack?: boolean;
    refetchEnabled?: boolean;
    backAction?: BackActionTypes;
    calendarView?: boolean;
    unusedPartsButton?: boolean;

  };
  sortingKeys: SortingKeys;
  groupingKeys: GroupingKeys;
  sortingVar: SortingVar;
  groupByOrderEnabled: boolean;
}
/** Example state interface
 * feedback: boolean
 */

// Interface for store actions
interface Actions extends SliceCaseReducers<State> {
  menuInit: (state: State) => State;
  setAppBar: (
    state: State,
    action: PayloadAction<{
      title: string;
      goingBack?: boolean;
      sortingType?: SortingPage;
      refetchEnabled?: boolean;
      backAction?: BackActionTypes;
      calendarView?: boolean;
      unusedPartsButton?: boolean;
    }>
  ) => State;
  setSorting: (
    state: State,
    action: PayloadAction<{ type: SortingPage; sorting: Sorting<SortingKey> }>
  ) => State;
  setGrouping: (
    state: State,
    action: PayloadAction<{ type: SortingPage; grouping: Group<GroupingKey> }>
  ) => State;
}
/** Example function interface
 * setOpen: (
 *    state: State,
 *    action: PayloadAction<{ dialogName: string; open: boolean }>
 * ) => State;
 */

// Interface for store selectors (if necessary)
// interface Selectors {}
/** Example function interface
 * selectFeedback: (
 *    state: RootState
 * ) => boolean;
 */

// Definition of actual (initial) state
export const initialState: State = {
  appBar: {
    title: "",
    goingBack: undefined,
  },
  sortingKeys: {
    job: jobSortingKeys,
    planner: jobSortingKeys,
    history: historySortingKeys,
    serviceHistory: historySortingKeys,
  },
  groupingKeys: {
    job: jobGroupingKeys,
    planner: jobGroupingKeys,
    history: historyGroupingKeys,
    serviceHistory: historyGroupingKeys,
  },
  sortingVar: {
    job: {
      sorting: defaultJobSorting,
      grouping: defaultJobGroup,
    },
    planner: {
      sorting: defaultJobSorting,
      grouping: defaultJobGroup,
    },
    history: {
      sorting: defaultHistorySorting,
      grouping: defaultHistoryGroup,
    },
    serviceHistory: {
      sorting: defaultHistorySorting,
      grouping: defaultHistoryGroup,
    },
  },
  groupByOrderEnabled: false,
};
/** Example state
 * feedback: false
 */

// Definition of actual actions
const actions: Actions = {
  menuInit: (state) => {
    state.sortingVar.job.sorting =
      fromLocal<Sorting<JobSortingKey>>("jobSorting") || defaultJobSorting;
    state.sortingVar.planner.sorting =
      fromLocal<Sorting<JobSortingKey>>("plannerSorting") || defaultJobSorting;
    state.sortingVar.history.sorting =
      fromLocal<Sorting<HistorySortingKey>>("historySorting") || defaultHistorySorting;
    state.sortingVar.serviceHistory.sorting =
      fromLocal<Sorting<HistorySortingKey>>("serviceHistorySorting") || defaultHistorySorting;
    state.sortingVar.job.grouping =
      fromLocal<Group<JobGroupingKey>>("jobGrouping") || defaultJobGroup;
    state.sortingVar.planner.grouping =
      fromLocal<Group<JobGroupingKey>>("plannerGrouping") || defaultJobGroup;
    state.sortingVar.history.grouping =
      fromLocal<Group<HistoryGroupingKey>>("historyGrouping") || defaultHistoryGroup;
    state.sortingVar.serviceHistory.grouping =
      fromLocal<Group<HistoryGroupingKey>>("serviceHistoryGrouping") || defaultHistoryGroup;
    return state;
  },
  setAppBar: (state, { payload }) => {
    state.appBar = { ...payload };
    return state;
  },
  setSorting: (state, { payload: { type, sorting } }) => {
    state.sortingVar[type].sorting = { ...sorting };
    return state;
  },
  setGrouping: (state, { payload: { type, grouping } }) => {
    state.sortingVar[type].grouping = { ...grouping };
    return state;
  },
};
/** Example function
 * setOpen: (state, { payload: { dialogName, open } }) => {
 *    state[dialogName] = open;
 *    return state;
 * },
 */

// Definition of actual selectors
// const selectors: Selectors = {};
/** Example function
 * selectFeedback: ({dialog}) => dialog.feedback
 */

// * menu: Name of store with lowercase letters
const storeBase = createSlice<State, Actions>({
  name: "menu",
  initialState,
  reducers: actions,
});

// To be imported and added in store/reducers
export default storeBase.reducer;

// Export all actions created in store, to be used in components
// I.e, if using setOpen defined in examples above, it would look like this
// export const { setOpen } = storeBase.actions;
export const { menuInit, setAppBar, setSorting, setGrouping } = storeBase.actions;

// Export all selectors created in store, to be used in components
// I.e, if using selectFeedback defined in examples above, it would look like this
// export const { selectFeedback } = selectors;
// export const {} = selectors;
