import { Check as CheckIcon, Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { CardContent, Grid, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { isPartRequested, isPartReturnRequested, isPartUsed } from "helpers";
import { UsedPart } from "models/usedPart";
import { PartType, StockStore } from "operations/schema/schema";

import { useAppDispatch, useAppSelector } from "store";
import { removePart, selectSelectedJobVisit, updatePart } from "store/slices/jobs.store";

import DecreaseButton from "components/DecreaseButton";
import IncreaseButton from "components/IncreaseButton";
import { PromptDialog } from "components/PromptDialog";
import StyledCard from "components/StyledCard";
import { StyledCardActions } from "components/StyledCardActions";
import StyledTextField from "components/StyledTextField";

import { AddPartEnum } from "./AddPartEnum";

const PREFIX = "UsedPart";

const classes = {
  buttonWrapper: `${PREFIX}-buttonWrapper`,
  error: `${PREFIX}-error`,
  buttonDisabled: `${PREFIX}-buttonDisabled`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  width: "100%",

  [`& .${classes.buttonWrapper}`]: {
    marginTop: "10px",
  },

  [`& .${classes.error}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.buttonDisabled}`]: {
    border: `1px solid rgba(0, 0, 0, 0.26)`,
  },
}));

interface UsedPartProps {
  maxQuantity: number;
  part: PartType;
  usedPart: UsedPart;
  setCountEditItems: (amount: number) => void;
  dialogType: AddPartEnum;
  setTabIndex: Dispatch<SetStateAction<string>>;
}

export const UsedPartComponent: FC<UsedPartProps> = (props) => {
  const { maxQuantity, part, usedPart, setCountEditItems, dialogType, setTabIndex } = props;

  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { usedParts } = useAppSelector(selectSelectedJobVisit);
  const [openEditPart, setOpenEditPart] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [tempQty, setTempQty] = useState<string>((part.quantity || 0).toString());
  const [qtyError, setQtyError] = useState<string>("");

  const changeQty = (quantity: number) => {
    setTempQty(quantity.toString());
    dispatch(
      updatePart({
        part: {
          ...usedPart,
          part: {
            ...part,
            quantity,
          },
        },
      })
    );
  };

  if (maxQuantity === undefined && isPartUsed(part)) {
    return null;
  }

  const changeQtyFromInput = (value: string) => {
    setTempQty(value);
    const intValue = parseInt(value);
    var limit = maxQuantity ?? 1000;

    if (intValue > 0 && intValue <= limit) {
      changeQty(intValue);
      setQtyError("");
    } else {
      if (limit >= 1) {
        setQtyError(
          intl.formatMessage(
            {
              id: "part.quantityBetween",
            },
            {
              qty: limit,
            }
          )
        );
      }
    }
  };

  const editJobPart = () => {
    setCountEditItems(!openEditPart ? +1 : -1);
    setTempQty((part.quantity || 0).toString());
    setQtyError("");
    setOpenEditPart(!openEditPart);
  };

  const removeJobPart = () => {
    // TODO; Fix undefinable issue ⬇
    dispatch(removePart({ id: part.id!, stockStore: part.stockStore! }));
    if (!usedParts.some((p) => isPartReturnRequested(p.part!))) {
      setTabIndex("usedParts");
    }
  };

  const isAddButtonDisabled = !!maxQuantity && (part.quantity || 0) >= maxQuantity;

  const stockLabelTranslatable =
    part.storeLabel === StockStore.Customer
      ? intl.formatMessage({ id: "general.customer" }).toUpperCase()
      : part.storeLabel === StockStore.Engineer
      ? intl.formatMessage({ id: "general.engineer" }).toUpperCase()
      : part.storeLabel === StockStore.Locational
      ? intl.formatMessage({ id: "part.locational" }).toUpperCase()
      : part.storeLabel === StockStore.Nonstock
      ? intl.formatMessage({ id: "part.nonStock" }).toUpperCase()
      : intl.formatMessage({ id: "part.other" }).toUpperCase();

  return (
    <StyledGrid item xs={12} sm={6} lg={4}>
      <StyledCard key={part.id}>
        <CardContent sx={{ width: "100%" }}>
          <Typography variant="body1" component="h3">
            {part.description}
          </Typography>
          <Typography color="textSecondary">{part.partNumber}</Typography>
          {dialogType === AddPartEnum.AddPart && (
            <Typography color="textSecondary">
              {isPartRequested(part)
                ? intl.formatMessage({ id: "part.requested" })
                : stockLabelTranslatable}
            </Typography>
          )}
          <Typography variant="body2" component="p">
            <FormattedMessage id="general.quantity" />: {!openEditPart && part.quantity}
          </Typography>
          {openEditPart && (
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              className={classes.buttonWrapper}
              wrap="nowrap"
            >
              <Grid item xs={2}>
                <DecreaseButton
                  disabled={part.quantity === 1}
                  className={part.quantity === 1 ? classes.buttonDisabled : ""}
                  onClick={() => changeQty((part.quantity || 0) - 1)}
                />
              </Grid>
              <Grid item xs={5} textAlign="center">
                <StyledTextField
                  value={tempQty}
                  id="quantity"
                  type="number"
                  onChange={(event) => changeQtyFromInput(event.target.value)}
                  helperText={qtyError}
                  error={qtyError !== ""}
                  style={{ width: "70%" }}
                />
              </Grid>
              <Grid item xs={2}>
                <IncreaseButton
                  disabled={isAddButtonDisabled}
                  className={isAddButtonDisabled ? classes.buttonDisabled : ""}
                  onClick={() => changeQty((part.quantity || 0) + 1)}
                />
              </Grid>
            </Grid>
          )}
        </CardContent>
        <StyledCardActions>
          <IconButton
            aria-label={intl.formatMessage({ id: "general.delete" })}
            onClick={() => setConfirmationDialog(true)}
            size="large"
          >
            <DeleteIcon color="error" />
          </IconButton>
          {openEditPart ? (
            <IconButton
              aria-label={intl.formatMessage({ id: "general.save" })}
              onClick={editJobPart}
              size="large"
            >
              <CheckIcon color="primary" />
            </IconButton>
          ) : (
            <IconButton
              aria-label={intl.formatMessage({ id: "general.edit" })}
              onClick={editJobPart}
              size="large"
            >
              <EditIcon color="action" />
            </IconButton>
          )}
        </StyledCardActions>
      </StyledCard>
      <PromptDialog
        aria-labelledby="form-dialog-title"
        open={confirmationDialog}
        setOpen={setConfirmationDialog}
        okText={intl.formatMessage({ id: "general.confirm" })}
        onOk={removeJobPart}
        title={<FormattedMessage id="part.remove" />}
        promptContent={<FormattedMessage id="visit.part.removePrompt" />}
      />
    </StyledGrid>
  );
};
