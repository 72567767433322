import {
  Action,
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { RootState } from "store";
import { getChecklistStatus, isEmpty, toDateString } from "helpers";
import { jobsDictionaryToArray } from "helpers/jobsDictionaryToArray";
import { cloneDeep, isEqual } from "lodash";
import { CustomerSimple } from "models/CustomerSimple";
import { Job, Jobs } from "models/Job";
import { defaultJobFilter, JobFilter, JobFilterKeys } from "models/JobFilter";
import {
  ChecklistType,
  EquipmentType,
  FileType,
  JobCategoryType,
  JobStatus,
  JobSymptomType,
  PartStatus,
  QuestionDataType,
  StockStore,
  WorkNoteType,
  WorkNoteTypeEnum,
  WorkTaskType,
} from "operations/schema/schema";
import { asyncMutations, mutationBuilder } from "./jobs.mutations";
import { asyncQueries, queryBuilder } from "./jobs.queries";
import { User } from "models/user";
import { UsedPart } from "models/usedPart";
import { Times } from "models/travelTimes";
import { ValidationError } from "yup";
import { EngineerSignoffValidationSchema } from "models/EngineerSignoffValidationSchema";
import { VisitValidationSchema } from "models/VisitValidationSchema";
import { TravelValidationSchema } from "models/TravelValidationSchema";
import { JobVisitType } from "models/JobVisitType";
import { fromLocal } from "helpers/fromLocal";
import { initializeJob } from "helpers/initializeJob";
import { Inspection } from "models/inspection";

// Interface for state
export interface State {
  jobs: Jobs;
  lastLoaded: string | null;
  jobFilter: JobFilter;
  loadingJob?: boolean;
  loadingJobs?: boolean;
  loadingRelatedJobs?: boolean;
  newJobIds: string[];
  hideNewJobNotification: boolean;
  filterOptions: {
    customers: CustomerSimple[];
    loadingCustomers: boolean;
  };
  changeJobEquipment: {
    equipments: EquipmentType[];
    loading: boolean;
    loadingEquipments: boolean;
  };
  rejectJob: {
    open: boolean;
    loading: boolean;
    text: string;
  };
  updateEquipment: {
    open: boolean;
    loading: boolean;
  };
  jobVisits: {
    [x: string]: JobVisitType;
  };
  selectedJobId: string | undefined;
  machineProperties: {
    open: boolean;
    loading: boolean;
  };
  equipmentSpecifications: {
    open: boolean;
    loading: boolean;
  };
  contactsLoading: boolean;
  unusedPreOrderedParts: {
    open: boolean;
  };
  selectedInspection: Inspection | null;
}
/** Example state interface
 * feedback: boolean
 */

// Interface for store actions
interface Actions extends SliceCaseReducers<State> {
  loadJobVisitsInit: (state: State, action: Action) => State;
  getJobsOnQueued: (state: State, action: Action) => State;
  handleCloseRejectJob: (state: State, action: Action) => State;
  handleCloseUpdateEquipment: (state: State, action: Action) => State;
  resetJobFilter: (state: State, action: Action) => State;
  resetJobStatus: (state: State, action: PayloadAction<{ jobId: string }>) => State;
  setJobFilter: (
    state: State,
    action: PayloadAction<{ jobFilter: JobFilter; hideNewJobNotification: boolean }>
  ) => State;
  setJobStatus: (
    state: State,
    action: PayloadAction<{ jobId: string; status: JobStatus }>
  ) => State;
  setRejectJobOpen: (state: State, action: PayloadAction<{ open: boolean }>) => State;
  setRejectJobText: (state: State, action: PayloadAction<{ text: string }>) => State;
  setUpdateEquipmentOpen: (state: State, action: PayloadAction<{ open: boolean }>) => State;
  setViewUnusedPreOrderedPartsOpen: (
    state: State,
    action: PayloadAction<{ open: boolean }>
  ) => State;
  clearNewJobIds: (state: State, action: Action) => State;
  filterNewJobIds: (state: State, action: PayloadAction<{ jobId: string }>) => State;
  initializeVisit: (
    state: State,
    action: PayloadAction<{
      job: Job;
      jobCategories: JobCategoryType[];
      symptoms: JobSymptomType[];
      userVar: User;
      hasCauses: boolean;
    }>
  ) => State;
  setVisitCompleted: (state: State, action: Action) => State;
  setTravelTimes: (state: State, action: PayloadAction<{ times: Times[] }>) => State;
  setTravelEta: (state: State, action: PayloadAction<{ eta: string }>) => State;
  setTravelMileage: (state: State, action: PayloadAction<{ mileage: number }>) => State;
  setSelectedJob: (state: State, action: PayloadAction<{ jobId: string | undefined }>) => State;
  setAutoEndTime: (state: State, action: PayloadAction<{ autoEndTime: boolean }>) => State;
  // Form
  setVisitValue: <K extends Extract<keyof JobVisitType, string>>(
    state: State,
    action: PayloadAction<{ key: K; value: JobVisitType[K]; shouldValidate?: boolean }>
  ) => State;
  validateVisit: (state: State, action: Action) => State;
  // Checklists
  updateChecklist: (
    state: State,
    action: PayloadAction<{ checklist: ChecklistType; index: number }>
  ) => State;
  updateInspection: (state: State, action: PayloadAction<{ inspection: Inspection }>) => State;
  setSelectedInspection: (
    state: State,
    action: PayloadAction<{ inspection: Inspection | null }>
  ) => State;
  uploadedChecklists: (state: State, action: Action) => State;
  // Files
  addFile: (state: State, action: PayloadAction<{ file: FileType }>) => State;
  removeFile: (state: State, action: PayloadAction<{ id: string }>) => State;
  updateFile: (state: State, action: PayloadAction<{ file: FileType }>) => State;
  uploadedFiles: (state: State, action: Action) => State;
  // Parts
  addPart: (state: State, action: PayloadAction<{ part: UsedPart }>) => State;
  addParts: (state: State, action: PayloadAction<{ parts: UsedPart[] }>) => State;
  removePart: (
    state: State,
    action: PayloadAction<{ id: string; stockStore: StockStore }>
  ) => State;
  updatePart: (state: State, action: PayloadAction<{ part: UsedPart }>) => State;
  uploadedAddedParts: (state: State, action: PayloadAction<{ addedParts: string[] }>) => State;
  uploadedRequestedParts: (state: State, action: Action) => State;
  // Signature
  setSignatureCustomer: (
    state: State,
    action: PayloadAction<{ signatureData: string | undefined }>
  ) => State;
  setSignatureEngineer: (
    state: State,
    action: PayloadAction<{ signatureData: string | undefined }>
  ) => State;
  // WorkTasks
  updateWorkTask: (state: State, action: PayloadAction<{ workTask: WorkTaskType }>) => State;
  addTravel: (state: State) => State;
  stopTravel: (state: State) => State;
  //EquipmentPropsDialog
  setMachinePropertiesOpen: (state: State, actions: PayloadAction<{ open: boolean }>) => State;
  //EquipmentSpecificationsDialog
  setEquipmentSpecificationsOpen: (
    state: State,
    actions: PayloadAction<{ open: boolean }>
  ) => State;
  addPartsMany: (
    state: State,
    action: PayloadAction<{ jobsWithAddedParts: { id: string; parts: UsedPart[] }[] }>
  ) => State;
}
/** Example function interface
 * setOpen: (
 *    state: State,
 *    action: PayloadAction<{ dialogName: string; open: boolean }>
 * ) => State;
 */

// Interface for store selectors (if necessary)
interface Selectors {
  selectJobLoaded: (state: RootState) => boolean;
  selectJob: (state: RootState, id: string | undefined) => Job | undefined;
  selectJobs: (state: RootState) => Job[];
  selectIncompleteJobs: (state: RootState) => Job[];
  selectLoadingJobs: (state: RootState) => boolean;
  selectRejectJobOpen: (state: RootState) => boolean;
  selectRejectJobLoading: (state: RootState) => boolean;
  selectRejectJobText: (state: RootState) => string;
  selectUpdateEquipmentOpen: (state: RootState) => boolean;
  selectUpdateEquipmentLoading: (state: RootState) => boolean;
  selectJobFilter: (state: RootState) => JobFilter;
  selectJobFilterCount: (state: RootState) => number;
  selectNewJobIds: (state: RootState) => string[];
  selectVisitLoaded: (state: RootState) => boolean;
  selectTravelTimes: (state: RootState) => Times[];
  selectSelectedJob: (state: RootState) => Job;
  selectSelectedJobVisit: (state: RootState) => JobVisitType;
  selectWorkNotes: (state: RootState) => WorkNoteType[];
  selectVisitSelectedJobId: (state: RootState) => string | undefined;
  selectMachinePropertiesOpen: (state: RootState) => boolean;
  selectMachinePropertiesLoading: (state: RootState) => boolean;
  selectEquipmentSpecificationsOpen: (state: RootState) => boolean;
  selectEquipmentSpecificationsLoading: (state: RootState) => boolean;
  selectTravelTabInvalid: (state: RootState) => boolean;
  selectChecklistsIncomplete: (state: RootState) => boolean;
  selectVisitCanComplete: (state: RootState) => boolean;
  selectIsJobInProgress: (state: RootState) => boolean;
  selectVisitStarted: (state: RootState) => boolean;
  selectContactsLoading: (state: RootState) => boolean;
  selectUnusedPreOrderedPartsCount: (state: RootState) => number;
  selectJobsWithPreOrderedParts: (state: RootState) => Job[];
}
/** Example function interface
 * selectFeedback: (
 *    state: RootState
 * ) => boolean;
 */

// Definition of actual (initial) state
export const initialState: State = {
  jobs: {},
  lastLoaded: null,
  newJobIds: [],
  hideNewJobNotification: false,
  jobFilter: {
    ...defaultJobFilter,
  },
  filterOptions: {
    customers: [],
    loadingCustomers: false,
  },
  changeJobEquipment: {
    equipments: [],
    loading: false,
    loadingEquipments: false,
  },
  rejectJob: {
    open: false,
    loading: false,
    text: "",
  },
  updateEquipment: {
    open: false,
    loading: false,
  },
  jobVisits: {},
  selectedJobId: undefined,
  machineProperties: {
    open: false,
    loading: false,
  },
  equipmentSpecifications: {
    open: false,
    loading: false,
  },
  contactsLoading: false,
  unusedPreOrderedParts: {
    open: false,
  },
  selectedInspection: null,
};
/** Example state
 * feedback: false
 */

export const storeVisitsToLocal = (state: State) => {
  const copiedVisits = JSON.parse(JSON.stringify(state.jobVisits));
  for (const key in copiedVisits) {
    copiedVisits[key].files = [];
  }
  try {
    localStorage.setItem("jobVisits", JSON.stringify(copiedVisits));
  } catch {
    return;
  }
};

// Definition of actual actions
const actions: Actions = {
  loadJobVisitsInit: (state) => {
    const loadedVisits =
      fromLocal<{
        [x: string]: JobVisitType;
      }>("jobVisits") || {};

    state.jobVisits = Object.assign({}, state.jobVisits, loadedVisits);
    return state;
  },
  getJobsOnQueued(state) {
    state.loadingJobs = false;
    return state;
  },
  setRejectJobOpen: (state, { payload: { open } }) => {
    state.rejectJob.open = open;
    return state;
  },
  setRejectJobText: (state, { payload: { text } }) => {
    state.rejectJob.text = text;
    return state;
  },
  handleCloseRejectJob: (state) => {
    state.rejectJob.text = "";
    state.rejectJob.open = false;
    return state;
  },
  setUpdateEquipmentOpen: (state, { payload: { open } }) => {
    state.updateEquipment.open = open;
    return state;
  },
  handleCloseUpdateEquipment: (state) => {
    state.updateEquipment.open = false;
    return state;
  },
  setViewUnusedPreOrderedPartsOpen: (state, { payload: { open } }) => {
    state.unusedPreOrderedParts.open = open;
    return state;
  },
  setJobFilter(state, { payload: { jobFilter, hideNewJobNotification } }) {
    state.jobFilter = jobFilter;
    state.hideNewJobNotification = hideNewJobNotification;
    return state;
  },
  resetJobFilter(state) {
    state.jobFilter = { ...defaultJobFilter };
    state.hideNewJobNotification = true;
    return state;
  },
  setJobStatus: (state, { payload: { jobId, status } }) => {
    if (!state.jobs[jobId] || state.jobs[jobId].status === status) return state;
    state.jobs[jobId].previousStatus = state.jobs[jobId].status || undefined;
    state.jobs[jobId].status = status;
    return state;
  },
  resetJobStatus: (state, { payload: { jobId } }) => {
    if (!state.jobs[jobId] || !state.jobs[jobId].previousStatus) return state;
    state.jobs[jobId].status = state.jobs[jobId].previousStatus;
    state.jobs[jobId].previousStatus = undefined;
    return state;
  },
  clearNewJobIds: (state) => {
    state.newJobIds = [];
    return state;
  },
  filterNewJobIds: (state, { payload: { jobId } }) => {
    state.newJobIds = state.newJobIds.filter((id) => id !== jobId);
    return state;
  },
  initializeVisit: (state, { payload: { job, jobCategories, symptoms, hasCauses, userVar } }) => {
    let { selectedJobId, jobVisits } = state;
    if (selectedJobId && !jobVisits[selectedJobId]) {
      initializeJob(state, job, hasCauses, jobCategories, symptoms, userVar);
    }
    storeVisitsToLocal(state);
    return state;
  },
  setVisitCompleted: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    // TODO; Merge data into jobs.store
    delete jobVisits[selectedJobId];
    storeVisitsToLocal(state);
    return state;
  },
  setTravelTimes: (state, { payload: { times } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId) return state;
    let visit = jobVisits[selectedJobId];
    jobVisits[selectedJobId].travelTimes = [...times];
    const validationSchema = TravelValidationSchema();
    const isValid = validationSchema.isValidSync(visit);
    if (!isValid) {
      try {
        validationSchema.validateSync({ ...visit }, { strict: true, abortEarly: false });
      } catch (e: any) {
        let { inner } = e as ValidationError;
        let errors: any = {};
        for (let error of inner) {
          if (!error.path) continue;
          errors[error.path] = error.message;
        }
        visit.errors = { ...errors };
      }
    } else {
      visit.errors = {};
    }
    storeVisitsToLocal(state);
    return state;
  },
  setTravelEta: (state, { payload: { eta } }) => {
    if (!state.selectedJobId) return state;
    state.jobVisits[state.selectedJobId].travelEta = eta;
    storeVisitsToLocal(state);
    return state;
  },
  setTravelMileage: (state, { payload: { mileage } }) => {
    if (!state.selectedJobId) return state;
    state.jobVisits[state.selectedJobId].travelMileage = mileage;
    storeVisitsToLocal(state);
    return state;
  },
  setSelectedJob: (state, { payload: { jobId } }) => {
    state.selectedJobId = jobId;
    return state;
  },
  setAutoEndTime: (state, { payload: { autoEndTime } }) => {
    if (!state.selectedJobId) return state;
    state.jobVisits[state.selectedJobId].autoEndTime = autoEndTime;
    storeVisitsToLocal(state);
    return state;
  },

  // Form
  setVisitValue: (state, { payload: { key, value, shouldValidate = true } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const visit = jobVisits[selectedJobId];
    // const visitForm = visit.form;
    visit[key] = value;
    if (shouldValidate) {
      const validationSchema = VisitValidationSchema(visit.autoEndTime, visit.hasCauses);
      const isValid = validationSchema.isValidSync(visit);
      if (!isValid) {
        try {
          validationSchema.validateSync({ ...visit }, { strict: true, abortEarly: false });
        } catch (e: any) {
          let { inner } = e as ValidationError;
          let errors: any = {};
          for (let error of inner) {
            if (!error.path) continue;
            errors[error.path] = error.message;
          }
          visit.errors = { ...errors };
        }
      } else {
        visit.errors = {};
      }

      const signoffValidation = EngineerSignoffValidationSchema(
        visit.hasCategories,
        visit.followUp.followUpChecked
      );
      const isSignoffValid = signoffValidation.isValidSync(visit);
      if (!isSignoffValid) {
        try {
          signoffValidation.validateSync({ ...visit }, { strict: true, abortEarly: false });
        } catch (e: any) {
          let { inner } = e as ValidationError;
          let errors: any = {};
          for (let error of inner) {
            if (!error.path) continue;
            errors[error.path] = error.message;
          }
          visit.signoffErrors = { ...errors };
        }
      } else {
        visit.signoffErrors = {};
      }
    }
    storeVisitsToLocal(state);
    return state;
  },
  validateVisit: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const visit = jobVisits[selectedJobId];
    // const visitForm = visit.form;
    const validationSchema = VisitValidationSchema(visit.autoEndTime, visit.hasCauses);
    const isValid = validationSchema.isValidSync(visit);
    if (!isValid) {
      try {
        validationSchema.validateSync({ ...visit }, { strict: true, abortEarly: false });
      } catch (e: any) {
        let { inner } = e as ValidationError;
        let errors: any = {};
        for (let error of inner) {
          if (!error.path) continue;
          errors[error.path] = error.message;
        }
        visit.errors = { ...errors };
      }
    } else {
      visit.errors = {};
    }

    const signoffValidation = EngineerSignoffValidationSchema(
      visit.hasCategories,
      visit.followUp.followUpChecked
    );
    const isSignoffValid = signoffValidation.isValidSync(visit);
    if (!isSignoffValid) {
      try {
        signoffValidation.validateSync({ ...visit }, { strict: true, abortEarly: false });
      } catch (e: any) {
        let { inner } = e as ValidationError;
        let errors: any = {};
        for (let error of inner) {
          if (!error.path) continue;
          errors[error.path] = error.message;
        }
        visit.signoffErrors = { ...errors };
      }
    } else {
      visit.signoffErrors = {};
    }
    // state.jobVisits[selectedJobId].form = visitForm;
    // yup.validate();
    storeVisitsToLocal(state);
    return state;
  },
  // Checklists
  updateChecklist: (state, { payload: { checklist, index } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { checklists } = jobVisits[selectedJobId];
    checklist.questions = checklist.questions.map((q) => {
      if (
        q.dataType === QuestionDataType.DateTime ||
        q.dataType === QuestionDataType.Date ||
        q.dataType === QuestionDataType.TimeOfDay
      ) {
        q.answer = toDateString(q.answer);
      }
      return q;
    });
    checklists[index] = {
      uploaded: false,
      checklist,
    };
    jobVisits[selectedJobId].checklists = [...checklists];
    storeVisitsToLocal(state);
    return state;
  },
  updateInspection: (state, { payload: { inspection } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { inspections } = jobVisits[selectedJobId];
    let index = inspections.findIndex((i) => i.specification.id === inspection.specification.id);
    console.log(inspection, index);
    if (index !== -1) {
      inspections[index] = { ...inspection };
      jobVisits[selectedJobId].inspections = [...inspections];
      if (
        state.selectedInspection &&
        state.selectedInspection.specification.id === inspection.specification.id
      ) {
        state.selectedInspection = inspection;
      }
      storeVisitsToLocal(state);
    }
    return state;
  },
  setSelectedInspection: (state, { payload: { inspection } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    state.selectedInspection = inspection;
    return state;
  },
  uploadedChecklists: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const { checklists } = jobVisits[selectedJobId];
    checklists.map((cl) => (cl.uploaded = true));
    jobVisits[selectedJobId].checklists = [...checklists];
    storeVisitsToLocal(state);
    return state;
  },
  // Files
  addFile: (state, { payload: { file } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { files } = jobVisits[selectedJobId];
    jobVisits[selectedJobId].files = [file, ...files];
    storeVisitsToLocal(state);
    return state;
  },
  removeFile: (state, { payload: { id } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { files } = jobVisits[selectedJobId];
    files = files.filter((f) => f.id !== id);
    jobVisits[selectedJobId].files = [...files];
    storeVisitsToLocal(state);
    return state;
  },
  updateFile: (state, { payload: { file } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { files } = jobVisits[selectedJobId];
    let index = files.findIndex((f) => f.id === file.id);
    files[index] = file;
    jobVisits[selectedJobId].files = [...files];
    storeVisitsToLocal(state);
    return state;
  },
  uploadedFiles: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    jobVisits[selectedJobId].files = [];
    storeVisitsToLocal(state);
    return state;
  },
  // Parts
  addPart: (state, { payload: { part } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { usedParts } = jobVisits[selectedJobId];
    jobVisits[selectedJobId].usedParts = [part, ...usedParts];
    if (part.part.status === PartStatus.Requested) {
      jobVisits[selectedJobId].followUp = {
        ...jobVisits[selectedJobId].followUp,
        followUpChecked: true,
        followUpDisabled: true,
        followUpPartsChecked: true,
        followUpPartsDisabled: true,
      };
    } else if (!usedParts.some((part) => part.part.status === PartStatus.Requested)) {
      jobVisits[selectedJobId].followUp = {
        ...jobVisits[selectedJobId].followUp,
        followUpChecked: false,
        followUpDisabled: false,
        followUpPartsChecked: false,
        followUpPartsDisabled: false,
      };
    }
    storeVisitsToLocal(state);
    return state;
  },
  addParts: (state, { payload: { parts } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { usedParts } = jobVisits[selectedJobId];
    jobVisits[selectedJobId].usedParts = [...parts, ...usedParts];
    if (parts.some((part) => part.part.status === PartStatus.Requested)) {
      jobVisits[selectedJobId].followUp = {
        ...jobVisits[selectedJobId].followUp,
        followUpChecked: true,
        followUpDisabled: true,
        followUpPartsChecked: true,
        followUpPartsDisabled: true,
      };
    } else if (!usedParts.some((part) => part.part.status === PartStatus.Requested)) {
      jobVisits[selectedJobId].followUp = {
        ...jobVisits[selectedJobId].followUp,
        followUpChecked: false,
        followUpDisabled: false,
        followUpPartsChecked: false,
        followUpPartsDisabled: false,
      };
    }
    storeVisitsToLocal(state);
    return state;
  },

  addPartsMany: (state, { payload: { jobsWithAddedParts } }) => {
    const { jobVisits } = state;

    jobsWithAddedParts.forEach((job) => {
      if (jobVisits[job.id]) {
        let { usedParts } = jobVisits[job.id];
        jobVisits[job.id].usedParts = [...job.parts, ...usedParts];
        if (job.parts.some((part) => part.part.status === PartStatus.Requested)) {
          jobVisits[job.id].followUp = {
            ...jobVisits[job.id].followUp,
            followUpChecked: true,
          };
        } else if (!usedParts.some((part) => part.part.status === PartStatus.Requested)) {
          jobVisits[job.id].followUp = {
            ...jobVisits[job.id].followUp,
            followUpChecked: false,
          };
        }
      }
    });
    storeVisitsToLocal(state);
    return state;
  },

  removePart: (state, { payload: { id, stockStore } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { usedParts } = jobVisits[selectedJobId];
    usedParts = usedParts.filter((p) => !(p.part.id === id && p.part.stockStore === stockStore));
    jobVisits[selectedJobId].usedParts = [...usedParts];
    if (!usedParts.some((part) => part.part.status === PartStatus.Requested)) {
      jobVisits[selectedJobId].followUp = {
        ...jobVisits[selectedJobId].followUp,
        followUpChecked: false,
        followUpDisabled: false,
        followUpPartsChecked: false,
        followUpPartsDisabled: false,
      };
    } else if (usedParts.some((part) => part.part.status === PartStatus.Requested)) {
      jobVisits[selectedJobId].followUp = {
        ...jobVisits[selectedJobId].followUp,
        followUpChecked: true,
        followUpDisabled: true,
        followUpPartsChecked: true,
        followUpPartsDisabled: true,
      };
    }
    storeVisitsToLocal(state);
    return state;
  },
  updatePart: (state, { payload: { part } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { usedParts } = jobVisits[selectedJobId];
    let index = usedParts.findIndex((p) => p.part.id === part.part.id);
    usedParts[index] = part;
    jobVisits[selectedJobId].usedParts = [...usedParts];
    storeVisitsToLocal(state);
    return state;
  },
  uploadedAddedParts: (state, { payload: { addedParts } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const { usedParts } = jobVisits[selectedJobId];
    usedParts.map((p) =>
      p.part.stockStore !== StockStore.Other && addedParts.includes(p.part.id!)
        ? (p.uploaded = true)
        : p
    );
    jobVisits[selectedJobId].usedParts = [...usedParts];
    storeVisitsToLocal(state);
    return state;
  },
  uploadedRequestedParts: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const { usedParts } = jobVisits[selectedJobId];
    usedParts.map((p) => (p.part.stockStore === StockStore.Other ? (p.uploaded = true) : p));
    jobVisits[selectedJobId].usedParts = [...usedParts];
    storeVisitsToLocal(state);
    return state;
  },
  // Signature
  setSignatureCustomer: (state, { payload: { signatureData } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    state.jobVisits[selectedJobId].signatureCustomer = signatureData;
    storeVisitsToLocal(state);
    return state;
  },
  setSignatureEngineer: (state, { payload: { signatureData } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    state.jobVisits[selectedJobId].signatureEngineer = signatureData;
    storeVisitsToLocal(state);
    return state;
  },
  // WorkTasks
  updateWorkTask: (state, { payload: { workTask } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { workTasks } = jobVisits[selectedJobId];
    let index = workTasks.findIndex((wt) => wt.id === workTask.id);
    workTasks[index] = workTask;
    jobVisits[selectedJobId].workTasks = [...workTasks];
    storeVisitsToLocal(state);
    return state;
  },
  addTravel: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const newTravelTime = {
      startDate: toDateString(Date.now()),
      startTime: toDateString(Date.now()),
    };
    if (jobVisits[selectedJobId].travelTimes.length === 0) {
      jobVisits[selectedJobId].travelTimes = cloneDeep([newTravelTime]);
    } else {
      jobVisits[selectedJobId].travelTimes = [
        ...jobVisits[selectedJobId].travelTimes,
        newTravelTime,
      ];
    }
    storeVisitsToLocal(state);
    return state;
  },
  stopTravel: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const lastIndex = jobVisits[selectedJobId].travelTimes.length - 1;
    if (lastIndex !== -1) {
      jobVisits[selectedJobId].travelTimes[lastIndex].stopDate = toDateString(Date.now());
      jobVisits[selectedJobId].travelTimes[lastIndex].stopTime = toDateString(Date.now());
    }
    storeVisitsToLocal(state);
    return state;
  },
  //EquipmentPropsDialog
  setMachinePropertiesOpen: (state, { payload: { open } }) => {
    state.machineProperties.open = open;
    return state;
  },
  //EquipmentSpecificationsDialog
  setEquipmentSpecificationsOpen: (state, { payload: { open } }) => {
    state.equipmentSpecifications.open = open;
    return state;
  },
};
/** Example function
 * setOpen: (state, { payload: { dialogName, open } }) => {
 *    state[dialogName] = open;
 *    return state;
 * },
 */

// Definition of actual selectors
const selectors: Selectors = {
  selectJobLoaded({ jobs: { jobs }, root: { selectedJobId } }) {
    if (selectedJobId && jobs[selectedJobId]) {
      return true;
    }
    return false;
  },
  selectJob({ jobs }, id) {
    return id ? jobs.jobs[id] : undefined;
  },
  selectJobs({ jobs }) {
    return jobsDictionaryToArray(jobs.jobs);
  },
  selectIncompleteJobs({ jobs }) {
    const jobList = jobsDictionaryToArray(jobs.jobs).filter(
      (j) => j.status !== JobStatus.Completed && j.status !== JobStatus.Rejected
    );
    return jobList;
  },
  selectLoadingJobs({ jobs: { loadingJobs } }) {
    return !!loadingJobs;
  },
  selectRejectJobOpen: ({ jobs: { rejectJob } }) => {
    return rejectJob.open;
  },
  selectRejectJobLoading: ({ jobs: { rejectJob } }) => {
    return rejectJob.loading;
  },
  selectRejectJobText: ({ jobs: { rejectJob } }) => {
    return rejectJob.text;
  },
  selectUpdateEquipmentOpen: ({ jobs: { updateEquipment } }) => {
    return updateEquipment.open;
  },
  selectUpdateEquipmentLoading: ({ jobs: { updateEquipment } }) => {
    return updateEquipment.loading;
  },
  selectJobFilter({ jobs: { jobFilter } }) {
    return jobFilter;
  },
  selectJobFilterCount: ({ jobs: { jobFilter } }) => {
    let count = 1; // Start at 1 as we always date filter
    for (const k of JobFilterKeys) {
      if (isEqual(defaultJobFilter[k], jobFilter[k]) || isEmpty(jobFilter[k])) continue;
      count++;
    }
    return count;
  },
  selectNewJobIds: ({ jobs }) => {
    return jobs.newJobIds;
  },
  selectVisitLoaded({ jobs: { selectedJobId, jobVisits } }) {
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      return false;
    }
    return true;
  },
  selectTravelTimes({ jobs: { jobs, jobVisits, selectedJobId } }) {
    if (!selectedJobId || !jobs[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    return jobVisits[selectedJobId].travelTimes;
  },
  selectSelectedJob({ jobs: { jobs, selectedJobId } }) {
    if (!selectedJobId || !jobs[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    return jobs[selectedJobId];
  },
  selectSelectedJobVisit({ jobs: { selectedJobId, jobVisits } }) {
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    return jobVisits[selectedJobId];
  },
  selectWorkNotes({ jobs: { jobs, selectedJobId } }) {
    if (!selectedJobId || !jobs[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    return jobs[selectedJobId].workNotes.filter((wn) => wn.type === WorkNoteTypeEnum.Ticket);
  },
  selectVisitSelectedJobId({ jobs: { selectedJobId } }) {
    return selectedJobId;
  },
  selectMachinePropertiesOpen: ({ jobs: { machineProperties } }) => {
    return machineProperties.open;
  },
  selectMachinePropertiesLoading: ({ jobs: { machineProperties } }) => {
    return machineProperties.loading;
  },
  selectEquipmentSpecificationsOpen: ({ jobs: { equipmentSpecifications } }) => {
    return equipmentSpecifications.open;
  },
  selectEquipmentSpecificationsLoading: ({ jobs: { equipmentSpecifications } }) => {
    return equipmentSpecifications.loading;
  },
  selectTravelTabInvalid: ({ jobs: { selectedJobId, jobVisits } }) => {
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    const visit = jobVisits[selectedJobId];

    // Actual validation issues will be caught via visit.errors check
    // but unmatched pairs do not validate correctly
    let travelHasMatchingStopTimes = true;
    if (visit.travelTimes.length > 0) {
      travelHasMatchingStopTimes = visit.travelTimes.every(({ stopDate, stopTime }) => {
        return !!stopDate && !!stopTime;
      });
    }
    return !travelHasMatchingStopTimes;
  },
  selectChecklistsIncomplete: ({ jobs: { selectedJobId, jobVisits } }) => {
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    const visit = jobVisits[selectedJobId];

    let checklistsComplete = true;
    if (visit.checklists.length > 0) {
      checklistsComplete = visit.checklists.every(({ checklist }) => {
        const { isComplete } = getChecklistStatus(checklist);

        return isComplete;
      });
    }

    return !checklistsComplete;
  },
  selectVisitCanComplete: ({ jobs: { selectedJobId, jobVisits } }) => {
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    const visit = jobVisits[selectedJobId];
    const isValid = !Object.keys(visit.errors).length;
    const hasAction = (visit.actionId1?.length || 0) > 0;

    let checklistsComplete = true;
    if (visit.checklists.length > 0) {
      checklistsComplete = visit.checklists.every(({ checklist }) => {
        const { isComplete } = getChecklistStatus(checklist);

        return isComplete;
      });
    }

    // Actual validation issues will be caught via visit.errors check
    // but unmatched pairs do not validate correctly
    let travelHasMatchingStopTimes = true;
    if (visit.travelTimes.length > 0) {
      travelHasMatchingStopTimes = visit.travelTimes.every(({ stopDate, stopTime }) => {
        return !!stopDate && !!stopTime;
      });
    }
    return (
      isValid &&
      hasAction &&
      checklistsComplete &&
      travelHasMatchingStopTimes
    );
  },
  selectIsJobInProgress: ({ jobs: { jobs, selectedJobId, jobVisits } }) => {
    if (!selectedJobId || !jobs[selectedJobId] || !jobVisits[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    const job = jobs[selectedJobId];
    const visit = jobVisits[selectedJobId];

    return (
      job.status === JobStatus.InProgress ||
      (!isEmpty(visit.workTimes) && visit.workTimes[0].startTime !== null) ||
      (!isEmpty(visit.travelTimes) && visit.travelTimes[0].startTime != null)
    );
  },
  selectVisitStarted: ({ jobs: { jobs, selectedJobId, jobVisits } }) => {
    if (!selectedJobId || !jobs[selectedJobId] || !jobVisits[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    const job = jobs[selectedJobId];
    const visit = jobVisits[selectedJobId];

    return (
      job.status === JobStatus.InProgress ||
      (!isEmpty(visit.workTimes) && visit.workTimes[0].startTime !== null)
    );
  },
  selectContactsLoading({ jobs: { contactsLoading } }) {
    return contactsLoading;
  },
  selectUnusedPreOrderedPartsCount({ jobs: { jobs, jobVisits } }) {
    let count = 0;
    const jobList = jobsDictionaryToArray(jobs);
    jobList.forEach((job) => {
      if (job.preOrderedParts.length === 0) return;
      const visit = jobVisits[job.id];
      let parts = visit.usedParts.filter((p) => p && p?.part.stockStore !== StockStore.Other);
      var usedPreordered = job.preOrderedParts.filter((pop) =>
        parts.some((up) => up?.part.id === pop?.id && up?.part.partNumber === pop?.partNumber)
      );
      count += job.preOrderedParts.length - usedPreordered.length;
    });
    return count;
  },

  selectJobsWithPreOrderedParts({ jobs: { jobs, jobVisits } }) {
    const jobList = jobsDictionaryToArray(jobs);
    return jobList.filter((job) => {
      if (job.preOrderedParts.length === 0) return false;
      const visit = jobVisits[job.id];
      let parts = visit.usedParts.filter((p) => p && p?.part.stockStore !== StockStore.Other);
      var usedPreordered = job.preOrderedParts.filter((pop) =>
        parts.some((up) => up?.part.id === pop?.id && up?.part.partNumber === pop?.partNumber)
      );
      return usedPreordered.length < job.preOrderedParts.length;
    });
  },
};

/** Example function
 * selectFeedback: ({dialog}) => dialog.feedback
 */

// * job: Name of store with lowercase letters
const storeBase = createSlice<State, Actions>({
  name: "jobs",
  initialState,
  reducers: actions,
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    queryBuilder(builder);
    mutationBuilder(builder);
  },
});

// To be imported and added in store/reducers
export default storeBase.reducer;
export const {
  handleCloseRejectJob,
  handleCloseUpdateEquipment,
  resetJobFilter,
  resetJobStatus,
  setJobFilter,
  setJobStatus,
  setRejectJobOpen,
  setRejectJobText,
  setUpdateEquipmentOpen,
  setViewUnusedPreOrderedPartsOpen,
  clearNewJobIds,
  filterNewJobIds,
  addFile,
  addPart,
  addParts,
  addPartsMany,
  initializeVisit,
  removeFile,
  removePart,
  setSelectedJob,
  setTravelTimes,
  setTravelEta,
  setTravelMileage,
  setVisitCompleted,
  setVisitValue,
  updateChecklist,
  updateInspection,
  setSelectedInspection,
  updateFile,
  updatePart,
  updateWorkTask,
  uploadedAddedParts,
  uploadedChecklists,
  uploadedFiles,
  uploadedRequestedParts,
  validateVisit,
  setSignatureCustomer,
  setSignatureEngineer,
  setAutoEndTime,
  addTravel,
  stopTravel,
  setMachinePropertiesOpen,
  setEquipmentSpecificationsOpen,
  loadJobVisitsInit,
} = storeBase.actions;

export const {
  selectJobLoaded,
  selectJob,
  selectJobs,
  selectIncompleteJobs,
  selectLoadingJobs,
  selectRejectJobLoading,
  selectRejectJobOpen,
  selectRejectJobText,
  selectUpdateEquipmentLoading,
  selectUpdateEquipmentOpen,
  selectJobFilter,
  selectJobFilterCount,
  selectNewJobIds,
  selectVisitLoaded,
  selectTravelTimes,
  selectSelectedJob,
  selectSelectedJobVisit,
  selectWorkNotes,
  selectVisitSelectedJobId,
  selectMachinePropertiesOpen,
  selectMachinePropertiesLoading,
  selectEquipmentSpecificationsOpen,
  selectEquipmentSpecificationsLoading,
  selectTravelTabInvalid,
  selectChecklistsIncomplete,
  selectVisitCanComplete,
  selectIsJobInProgress,
  selectVisitStarted,
  selectContactsLoading,
  selectUnusedPreOrderedPartsCount,
  selectJobsWithPreOrderedParts,
} = selectors;

export const {
  getJob,
  getJobs,
  getCustomers,
  getFiles,
  getNotes,
  getRelatedJobs,
  getVisits,
  getEquipment,
} = asyncQueries;

export const {
  rejectJob,
  acceptJob,
  updateEquipment,
  changeJobEquipment,
  completeVisit,
  startVisitTravel,
  startVisitWork,
  updateETA,
  updateVisitInformation,
  updateVisitTravel,
} = asyncMutations;
