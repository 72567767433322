import { Dispatch, FC, SetStateAction } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Autocomplete, Container, Grid } from "@mui/material";

import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";
import StyledTextField from "components/StyledTextField";
import { AddPartFilter } from "models/AddPartFilter";
import { useAppSelector } from "store";

type AddPartFilterDialogProps = {
  filter: AddPartFilter;
  setFilter: Dispatch<SetStateAction<AddPartFilter>>;
  onReset: () => void;
  onSubmit: () => void;
};

export const AddPartFilterDialog: FC<AddPartFilterDialogProps> = (props) => {
  const { filter, setFilter, onReset, onSubmit } = props;
  const intl = useIntl();
  const { manufacturers } = useAppSelector((state) => state.cache);

  return (
    <Container sx={{ mt: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={manufacturers}
            getOptionLabel={(option) => `${option.name}`}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                label={intl.formatMessage({ id: "part.manufacturers" })}
              />
            )}
            value={filter.selectedManufacturers}
            onChange={(_, value) => {
              setFilter({
                ...filter,
                selectedManufacturers: value,
              });
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            data-testid="AddPartFilterDialog-Manufacturer"
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <SecondaryButton onClick={onReset} data-testid="AddPartFilterDialog-ResetButton">
            <FormattedMessage id="filter.reset" />
          </SecondaryButton>
        </Grid>
        <Grid item xs={12}>
          <PrimaryButton onClick={onSubmit}>
            <FormattedMessage id="part.viewParts" />
          </PrimaryButton>
        </Grid>
      </Grid>
    </Container>
  );
};
