import { combineReducers } from "@reduxjs/toolkit";

import dialogStore from "store/slices/dialog.store";
import userStore from "store/slices/user.store";
import rootStore from "./root.store";
import bookInPartsStore from "./slices/bookInParts.store";
import cacheStore from "./slices/cache.store";
import calendarStore from "./slices/calendar.store";
import createJobStore from "./slices/dialogs/createJob.store";
import feedbackStore from "./slices/dialogs/feedback.store";
import noteStore from "./slices/dialogs/note.store";
import historyStore from "./slices/history.store";
import jobsStore from "./slices/jobs.store";
import menuStore from "./slices/menu.store";
import plannerStore from "./slices/planner.store";
import snackbarStore from "./slices/snackbar.store";
import stockStore from "./slices/stock.store";

export const reducers = combineReducers({
  bookInParts: bookInPartsStore,
  cache: cacheStore,
  calendar: calendarStore,
  createJob: createJobStore,
  dialog: dialogStore,
  feedback: feedbackStore,
  history: historyStore,
  jobs: jobsStore,
  menu: menuStore,
  note: noteStore,
  planner: plannerStore,
  root: rootStore,
  snackbar: snackbarStore,
  stock: stockStore,
  user: userStore,
});
