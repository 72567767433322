import { Container, Grid } from "@mui/material";
import { endOfDay } from "date-fns";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  HistoryFilter,
  HistoryFilterDateTypes,
  ServiceHistoryFilterStorageKey,
} from "models/HistoryFilter";
import { useAppDispatch, useAppSelector } from "store";
import {
  resetServiceHistoryFilter,
  selectServiceHistoryFilter,
  setServiceHistoryFilter,
} from "store/slices/history.store";

import { AsolviDatePicker } from "components/AsolviDatePicker";
import { DateFilterType, FilterDateSelect } from "components/FilterDateSelect";
import FullScreenDialog from "components/FullScreenDialog";
import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";

type HistoryFilterDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const ServiceHistoryFilterDialog: FC<HistoryFilterDialogProps> = (props) => {
  const { open, setOpen } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectServiceHistoryFilter);

  const dateOptions: DateFilterType[] = [
    {
      label: intl.formatMessage({ id: "filter.last7days" }),
      value: "week",
      class: "e2e-last-7-days-filter",
    },
    {
      label: intl.formatMessage({ id: "filter.lastMonth" }),
      value: "1month",
      class: "e2e-last-month-filter",
    },
    {
      label: intl.formatMessage({ id: "filter.last12months" }),
      value: "12month",
      class: "e2e-last-year-filter",
    },
    {
      label: intl.formatMessage({ id: "filter.last18months" }),
      value: "18month",
      class: "e2e-last-18-months-filter",
    },
    {
      label: intl.formatMessage({ id: "filter.last5visits" }),
      value: "last5",
      class: "e2e-last-5-filter",
    },
  ];

  dateOptions.push({
    label: intl.formatMessage({ id: "filter.specificDate" }),
    value: "specificDate",
    class: "e2e-specific-date-filter",
  });

  const setDate = function (type: HistoryFilterDateTypes) {
    var specificDate = null;
    if (type === "specificDate") specificDate = filter.specificDate;
    setFilter({
      ...filter,
      typeDate: type,
      specificDate: specificDate,
    });
  };
  const setSpecificDate = function (value: Date | null) {
    setFilter({
      ...filter,
      specificDate: value ? endOfDay(value) : null,
      typeDate: "specificDate",
    });
  };

  const setFilter = (filter: HistoryFilter) => {
    dispatch(setServiceHistoryFilter({ serviceHistoryFilter: filter }));
  };

  const resetFilter = () => {
    dispatch(resetServiceHistoryFilter());
  };

  const saveFilter = () => {
    localStorage.setItem(ServiceHistoryFilterStorageKey, JSON.stringify(filter));
  };

  return (
    <FullScreenDialog
      title={intl.formatMessage({ id: "filter.setFilters" })}
      open={open}
      setOpen={setOpen}
      onClose={saveFilter}
      child={
        <Container sx={{ mt: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FilterDateSelect value={filter.typeDate} options={dateOptions} onChange={setDate} />
            </Grid>
            {filter.typeDate === "specificDate" && (
              <Grid item xs={12}>
                <AsolviDatePicker
                  data-testid="ServiceHistoryFilterDialog-DatePickerFilter"
                  onChange={(value) => {
                    setSpecificDate(value);
                  }}
                  value={filter.specificDate}
                  label={intl.formatMessage({ id: "filter.specificDate" })}
                  clearable
                />
              </Grid>
            )}
            <Grid item xs={12} mt={2}>
              <SecondaryButton
                onClick={resetFilter}
                data-testid="ServiceHistoryFilterDialog-ResetButton"
              >
                <FormattedMessage id="filter.reset" />
              </SecondaryButton>
            </Grid>
            <Grid item xs={12}>
              <PrimaryButton
                data-testid="ServiceHistoryFilterDialog-SubmitButton"
                onClick={() => {
                  saveFilter();
                  setOpen(false);
                }}
                className="e2e-close-filter-button"
              >
                <FormattedMessage id={"history.viewServiceHistory"} />
              </PrimaryButton>
            </Grid>
          </Grid>
        </Container>
      }
    />
  );
};
