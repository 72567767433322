import { Container, Grid, Typography } from "@mui/material";
import { AsolviSwitch } from "components/AsolviSwitch";
import { Dispatch, FC, SetStateAction } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { InspectionFilters } from "models/InspectionsFilter";
import FullScreenDialog from "components/FullScreenDialog";

type InspectionFilterDialogProps = {
  open: boolean;
  handleClose: () => void;
  filter: InspectionFilters;
  setFilter: Dispatch<SetStateAction<InspectionFilters>>;
};

export const InspectionFilterDialog: FC<InspectionFilterDialogProps> = (props) => {
  const { filter, setFilter, handleClose, open } = props;
  const intl = useIntl();

  return (
    <FullScreenDialog
      title={intl.formatMessage({ id: "filter.setFilters" })}
      open={open}
      setOpen={() => handleClose()}
      child={
        <Container sx={{ mt: 2 }}>
          <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: "10px" }}>
            <Grid item>
              <Typography>
                <FormattedMessage id="inspections.showNonTestedOnly" />
              </Typography>
            </Grid>
            <Grid item>
              <AsolviSwitch
                key="hidetested"
                checked={filter.hideTested}
                onChange={() => {
                  setFilter({ ...filter, hideTested: !filter.hideTested });
                }}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: "10px" }}>
            <Grid item>
              <Typography>
                <FormattedMessage id="inspections.showFailedTestOnly" />
              </Typography>
            </Grid>
            <Grid item>
              <AsolviSwitch
                key="hidepassed"
                checked={filter.hidePassed}
                onChange={() => {
                  setFilter({ ...filter, hidePassed: !filter.hidePassed });
                }}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: "10px" }}>
            <Grid item>
              <Typography>
                <FormattedMessage id="inspections.showTestedOnly" />
              </Typography>
            </Grid>
            <Grid item>
              <AsolviSwitch
                key="hidefailed"
                checked={filter.hideFailed}
                onClick={() => {
                  setFilter({ ...filter, hideFailed: !filter.hideFailed });
                }}
              />
            </Grid>
          </Grid>
        </Container>
      }
    />
  );
};
