import { styled, ToggleButtonGroup } from "@mui/material";



    export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        "& .MuiToggleButtonGroup-grouped": {
        border: `1px solid ${theme.palette.grey[400]} !important`,
        borderRadius: theme.shape.borderRadius,
        "&:not(:first-of-type)": {
            borderRadius: theme.shape.borderRadius,
            marginLeft: "unset",
        },
        "&:first-of-type": {
            borderRadius: theme.shape.borderRadius,
            marginRight: theme.spacing(0.5),
        },
        "&:last-of-type": {
            marginLeft: theme.spacing(0.5),
        },
        "&.Mui-selected": {
            border: 0,
            color: theme.palette.common.white,
            "&.MuiToggleButton-success": {
            backgroundColor: theme.palette.success.main,
            },
            "&.Mui-error": {
            backgroundColor: theme.palette.error.main,
            },
            "&.MuiToggleButton-standard": {
            backgroundColor: theme.palette.secondary.main,
            },
        },
      },
    }));